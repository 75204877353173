//兼容处理
function setHtml() {
  // //获取设备宽度
  // var deviceWidth = document.documentElement.offsetWidth;
  // //给html标签设置fontSize，就是给rem赋值
  // document.documentElement.style.fontSize = deviceWidth / 375 + 'px';


  let whdef = 150 / 750 // 表示750的设计图,使用100PX的默认值
  let bodyWidth = document.body.clientWidth // 当前窗口的宽度
  let rem = bodyWidth * whdef // 以默认比例值乘以当前窗口宽度,得到该宽度下的相应FONT-SIZE值
  document.documentElement.style.fontSize = rem + 'px'
}

//窗口大小变化的时候执行
window.onresize = setHtml;
//页面初始加载时也要触发
setHtml();
