import request from '../request/request'

export default {
  getUrl(data) {
    return request({
      url: process.env.VUE_APP_BASEURL + '/api/http/get',
      method: 'post',
      data,
    })
  },
  getWXToken(data) {
    return request({
      url: 'https://dhapi.kang-cloud.com/api/open/common/get/wxsign2',
      method: 'post',
      data,
    })
  },
}
