/**
 * 设置sessionStorage
 * @param  {String}  参数
 * @param  {Any}  值
 */
export function setSessionStorage(key, value) {
  const _key = 'KC_' + key.toUpperCase()
  const data = {
    data: value,
    startTime: new Date().getTime()
  }

  const _data = window.btoa(window.encodeURIComponent(JSON.stringify(data)))

  window.sessionStorage.setItem(_key, _data)
}

/**
 * 获取sessionStorage
 * @param {string} 参数名
 */
export function getSessionStorage(key) {
  const _key = 'KC_' + key.toUpperCase()
  var str = window.sessionStorage.getItem(_key)
  if (str) {
    const data = JSON.parse(window.decodeURIComponent(window.atob(str))).data
    return data
  } else {
    return null
  }
}
