import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
  // return 'http://192.168.1.49:8090'
}

function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}

export default {
  count3(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/count3   ',
      method: 'post',
      data
    })
  },
  //预约历史
  bookHistory(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/bookHistory',
      method: 'post',
      data
    })
  },
  //常用设备
  mostUsedDevice(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/mostUsedDevice',
      method: 'post',
      data
    })
  },
  // 设备报障列表
  deviceRepairList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/reportList',
      method: 'post',
      data
    })
  },
  // 设备列表
  deviceOrderList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/deviceOrderList',
      method: 'post',
      data
    })
  },
  // 设备详情
  shareDeviceDetail(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/deviceDetail',
      method: 'post',
      data
    })
  },
  // 设备预约
  shareBook(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/book',
      method: 'post',
      data
    })
  },
  // 设备归还
  shareRevert(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/revert',
      method: 'post',
      data
    })
  },

// 设备科页面报障
  deviceReport(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/report',
      method: 'post',
      data
    })
  },
  // 设备报障
  shareReport(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/report',
      method: 'post',
      data
    })
  },
  // 消毒信息
  shareCleaninfo(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/cleanInfo',
      method: 'post',
      data
    })
  },
  // 取消预约
  shareCancelBooking(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/cancelBooking',
      method: 'post',
      data
    })
  },
  //扫码使用
  startUsing(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/startUsing',
      method: 'post',
      data
    })
  },
  //消毒清潔
  shareClean(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/clean',
      method: 'post',
      data
    })
  },
  //共享池配置列表
  sharePoolConfig(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/sharePoolConfig',
      method: 'post',
      data
    })
  },
  //共享订单流程
  shareData(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/data',
      method: 'post',
      data
    })
  },
  //检查消毒
  checkClean(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/checkClean',
      method: 'post',
      data
    })
  },
  //检查订单价格
  orderDetail(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/orderDetail',
      method: 'post',
      data
    })
  },
  //评价特点统计
  sharFeatures(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/features',
      method: 'post',
      data
    })
  },
  // 更新预计结束时间
  updateEstimatedEndTime(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/updateEstimatedEndTime',
      method: 'post',
      data
    })
  }
}
