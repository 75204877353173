<template>
  <div class="tabs1">
    <van-tabs :style="{'--color':this.color}" :ellipsis="false" v-model="activeTab" swipeable animated @change="tabChange" v-on="$listeners"
              v-bind="$attrs"
    >
      <van-tab  v-for="(item, index) in options" :key="index" :name="item.path" v-bind="item"></van-tab>
    </van-tabs>
  </div>
</template>

<script>
export default {
  name: 'tabs1',
  components: {},
  model: {
    prop: 'value',
    event: 'cValue'
  },
  props: {
    color: {
      default: '#fff'
    },

    value: {
      type: String,
      default: ''
    },
    options: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  data() {
    return {
      activeTab: ''
    }
  },
  watch: {
    value: {
      handler() {
        this.activeTab = this.value
      },
      immediate: true
    }
  },
  created() {
    this.$route.path
  },
  mounted() {
  },
  methods: {
    tabChange(e) {
      this.$emit('change', e)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';

.tabs1 {
  ::v-deep.van-tabs {
    .van-tabs__wrap {
      height: 32px;

      .van-tabs__nav {
        background: none;

        .van-tab {
          color: var(--color);
        }

        .van-tab--active {
          font-weight: 700;
        }

        .van-tabs__line {
          background: var(--color);
          width: 18px;
        }
      }
    }
  }
}


</style>
