<template>
	<div class="searchInput">
		<van-search v-model="inputValue" clearable show-action :placeholder="placeholder" @input="onInput" @clear="onClear" @search="onSearch">
			<template #action>
				<button class="search-btn" type="button" @click.stop="onSearch()">搜索</button>
			</template>
		</van-search>
	</div>
</template>

<script>
import { debounce } from './debounce.js';
export default {
	name: 'searchInput',
	components: {},
	model: {
		prop: 'value',
		event: 'cValue'
	},
	props: {
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: '搜索'
		}
	},
	data() {
		return {
			inputValue: ''
		};
	},
	watch: {
		value: {
			handler() {
				this.inputValue = this.value;
			},
			immediate: true
		}
	},
	created() {},
	mounted() {},
	methods: {
		onInput(e) {
			this.$emit('cValue', e);
			this.$emit('input', e);
			this.debounceChange();
		},
		debounceChange: debounce(function() {
			if (this.inputValue == '') {
				this.$emit('clear', '');
			}
			this.$emit('debounceChange');
			this.$emit('search', this.value);
		}, 1000),
		onClear() {
			this.debounceChange(1);
			this.$emit('search', this.value);
		},
		onSearch() {
			this.debounceChange(1);
			this.$emit('search', this.value);
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.searchInput {
	height: 32px;
	::v-deep.van-search {
		padding: 0;
		background: #ffffff;
		border-radius: 38px;
		border: 1px solid #3e73fb;
		overflow: hidden;
		.van-icon-search {
			color: #c8c9cc;
		}
		.van-search__content {
			background: #fff;
			.van-cell{
				padding: 3px 8px 3px 0;
			}
		}
		.van-search__action {
			padding-right: 2px;
			line-height: 30px;
		}
	}
	.search-btn {
		line-height: 14px;
    background-color: #3e73fb ;
		//background: linear-gradient(90deg, #72abfd 0%, #3e73fb 100%);
		border-radius: 38px;
		padding: 0px 14px;
		height: 26px;
		line-height: 26px;
		font-size: 14px;
		color: #fff;
	}
}
</style>
