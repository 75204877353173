import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  getEmergencySuppliesDetail(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/EmergencySupplies/list/detail',
      method: 'post',
      data
    })
  },

  getEmergencySupplies(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/EmergencySupplies/list',
      method: 'post',
      data
    })
  },
  getDeviceTrajectory(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/location/deviceLocation',
      method: 'post',
      data
    })
  },
  findAlarmById: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/alarm/findAlarmById',
      method: 'post',
      data
    })
  },
  disposeAlarm: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/alarm/disposeAlarm',
      method: 'post',
      data
    })
  },
  getAlertist: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/alarm/alarmList',
      method: 'post',
      data
    })
  }
  ,
  getAlertTypeList: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/alarm/queryAlarmTypeList2',
      method: 'post',
      data
    })
  }
  ,
  updateDeviceList: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/addOrUpdate',
      method: 'post',
      data
    })
  }
  ,
  getDeviceList: function(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/deviceList',
      method: 'post',
      data
    })
  }
  ,
// 设备列表
  getList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/sensor/list',
      method: 'post',
      data
    })
  }
  ,
  addDevice(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/sensor/addOrUpdate',
      method: 'post',
      data
    })
  },
  // 手术室简易列表
  theaterSimpleList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/operation/theater/simpleList',
      method: 'post',
      data
    })
  },
  getSearchDeviceList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/deviceList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getBrandList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/brand/getBrandList',
      method: 'post',
      responseType: 'json',
      params
    })
  }
  ,

  getModelList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/model/getModelList',
      method: 'post',
      responseType: 'json',
      params
    })
  }
  ,

  getDeviceCategoryTree(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/category/getDeviceCategoryTree',
      method: 'post',
      responseType: 'json',
      data
    })
  }
  ,
// 设备盘点列表
  getDeviceInventoryList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/sys/inventory/v2/list',
      method: 'post',
      responseType: 'json',
      params
    })
  }
  ,
// 获取盘点Id
  getDeviceInventoryInventory(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/sys/inventory/v2/inventory',
      method: 'post',
      responseType: 'json',
      params
    })
  }
  ,
// 开始盘点
  getDeviceInventoryDetailList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/sys/inventory/v2/detail/list',
      method: 'post',
      responseType: 'json',
      params
    })
  }
  ,
// 根据id查找历史盘点
  getDeviceInventoryHistoryList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/sys/inventory/v2/show',
      method: 'post',
      responseType: 'json',
      params
    })
  }
}
