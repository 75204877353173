let idConfig = {
  VUE_APP_IP:'http://assetapi.kang-cloud.com',
  VUE_APP_BASEURL:'http://ssoapi.kang-cloud.com',
  VUE_APP_ASSETURL:'http://assetapi.kang-cloud.com',
  VUE_APP_RFIDURL:'http://203.88.218.218:8097'
}

// let idConfig = {
//   VUE_APP_IP:'http://121.35.253.203:10000',
//   VUE_APP_BASEURL:'http://121.35.253.203:10000',
//   VUE_APP_ASSETURL:'http://121.35.253.203:18093'
// }

// let idConfig = {
//   VUE_APP_IP:'http://192.168.1.243',
//   VUE_APP_BASEURL:'http://ssoapi.kang-cloud.com',
//   VUE_APP_ASSETURL:'http://assetapi.kang-cloud.com'
// }


// let idConfig = {
//   VUE_APP_IP:'http://192.168.169.242',
//   VUE_APP_BASEURL:'http://192.168.169.242:10000',
//   VUE_APP_ASSETURL:'http://192.168.169.242:8093'
// }


function updateIpConfig() {
  if (typeof localStorage['ipConfig'] == 'undefined' || localStorage['ipConfig'] == null) {
    return;
  }
  try {
    let info = JSON.parse(localStorage['ipConfig']);
    for (const infoKey in info) {
      idConfig[infoKey] = info[infoKey];
    }
  } catch (e) {

  }
}

updateIpConfig();

export default idConfig;
