import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  // 获取人员列表
  getPersonalList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() +'/api/medic/list',
      method: 'post',
      data
    })
  },
  // 添加人员
  addPersonal(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() +'/api/medic/modifyMedic',
      method: 'post',
      data: {
        deleted: 0,
        ...data
      }
    })
  },
  // 删除人员
  deletedPersonal(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() +'/api/medic/modifyMedic',
      method: 'post',
      data: {
        deleted: 1,
        ...data
      }
    })
  },
  // 医护、护工手环数据查询
  getNurseStaffDeviceDetail(data) {
    return request({
      url: ASSETSERVICE + '/api/operationScheduling/getS2PROValidList',
      method: 'post',
      data
    })
  },
  // 患者列表
  patientList(data) {
    return request({
      url: ASSETSERVICE + '/api/operationScheduling/patientList',
      method: 'post',
      data
    })
  },
  
}
