<template>
  <div class="bottomBar">
    <bottom-bar-item v-for="(item, index) in barList"
                     @selectItem="selectItem"
                     :index="index"
                     :item-info="item"
                     :select="barSelect==index"
                     :width="`${barList.length == 0 ? 100 : 100 / barList.length}%`"
    >
    </bottom-bar-item>
  </div>
</template>

<script>
import BottomBarItem from '@/components/bottemBar/bottomBarItem'

export default {
  name: 'bottomBar',
  components: { BottomBarItem },
  data() {
    return {
      barSelect: 0,
      barList: []
    }
  },
  methods: {
    selectItem: function(res) {
      if (res.index == this.barSelect || res.index >= this.barList.length) {
        return
      }
      this.barSelect = res.index
      let item = this.barList[res.index];
      this.$bottomChange(item.url, item.query)
    },
    updateBarList: function(list, index = 0) {
      this.barList = list
      this.barSelect = index
      if (index == this.barSelect || index >= this.barList.length) {
        return
      }
      let item = this.barList[index];
      this.$bottomChange(item.url, item.query)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.bottomBar {
  background-color: white;
  width: 100%;
  height: 50px;
  border-top: 1px solid $border_color1;
}

</style>
