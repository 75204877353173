import {
  Loading
} from 'element-ui'

const install = function(Vue) {
  Vue.prototype.$clearLocal = function(path, query) {
    let list = ['historySearch', 'remeberCode', 'href']
    let data = {}
    for (let i = 0; i < list.length; i++) {
      let key = list[i]
      if (this.$valueIsExist(localStorage, key) == false) {
        continue
      }
      data[key] = localStorage[key]
    }
    localStorage.clear()
    for (const dataKey in data) {
      localStorage.setItem(dataKey, data[dataKey])
    }
  }

  Vue.prototype.$bottomChange = function(path, query) {
    this.$router.replace({ path: `/${path}`, query: query })
    // this.$router.push({ path: `/${path}`, query: query })
  }

  Vue.prototype.$pushWebView = function(path, query) {
    this.$router.push({
      path: `/${path}`,
      query: query
    })
  }

  Vue.prototype.$back = function(path, query) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type != 'weChat') {
      this.$router.go(-1)
    } else {
      this.navigateBack()
    }
  }

  Vue.prototype.$push = function(path, query) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type != 'weChat') {
      this.$router.push({
        path: path[0] == '/' ? path : `/${path}`,
        query: query
      })
    } else {
      const _path = path[0] == '/' ? path.substr(1, path.length - 1) : path
      this.navigateTo(_path, query)
    }
    // this.navigateTo(path, query)
    // this.$router.push({ path: `/${path}`, query: query })
  }

  Vue.prototype.$replace = function(path, query) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type != 'weChat') {
      this.$router.replace({
        path: path[0] == '/' ? path : `/${path}`,
        query: query
      })
    } else {
      const _path = path[0] == '/' ? path.substr(1, path.length - 1) : path
      this.redirectTo(_path, query)
    }
  }

  Vue.prototype.$getDataValue = function(info, key, defaultValue) {
    if (this.$valueIsExist(info, key) == false) {
      return defaultValue
    }
    return info[key]
  }

  Vue.prototype.$getPhotosId = function(photo) {
    if (photo.indexOf(',') < 0) {
      return photo
    }
    let list = photo.split(',')
    if (list.length == 0) {
      return photo
    }
    return list[0]
  }

  Vue.prototype.$replacePhoto = function(url, defaultImage = 'https://img0.baidu.com/it/u=1159755399,1068993229&fm=253&fmt=auto&app=138&f=JPEG?w=500&h=500') {

    if (typeof url == 'undefined' || url == null || url == '' || url.indexOf('http') < 0 || url.indexOf('?') < 0) {
      return defaultImage
    }
    let list = url.split('?')
    if (list.length < 2) {
      return defaultImage
    }
    let query = list[1]
    let nextList = query.split('&')
    let photoId = ''

    for (let i = 0; i < nextList.length; i++) {
      let str = nextList[i]
      if (str.indexOf('=') < 0 || str.indexOf('id') < 0) {
        continue
      }

      let dataList = str.split('=')
      if (dataList.length < 2) {
        return defaultImage
      }
      photoId = dataList[1]
      break
    }
    if (photoId == '') {
      return defaultImage
    }
    let newUrl = `${window.ipConfig.VUE_APP_BASEURL}/api/image/get?id=${photoId}`
    return newUrl
  }

  Vue.prototype.$valueIsExist = function(info, key) {
    if (typeof info == 'undefined' || info == null) {
      return false
    }
    if (typeof info[key] == 'undefined' || info[key] == null) {
      return false
    }
    return true
  }

  Vue.prototype.$VUEAPPDEVICECODE = function() {
    let VUE_APP_DEVICE_CODE = '资产编号'
    if (this.$valueIsExist(process, 'env') && this.$valueIsExist(process.env, 'VUE_APP_DEVICE_CODE')) {
      VUE_APP_DEVICE_CODE = process.env.VUE_APP_DEVICE_CODE
    }
    return  VUE_APP_DEVICE_CODE;
  }




  Vue.prototype.$changePxToRem = function(width) {
    return width
    // if (width.indexOf('%') >= 0) {
    // 	return width
    // } else if (width.indexOf('px') >= 0) {
    // 	let px = parseFloat(width.replace('px', ''))
    // 	return `${px / 16}rem`
    // }
    // return `${width / 16}rem`
  }

  Vue.prototype.startLoading = function(id) {
    const dom = document.getElementById(id)
    if (typeof dom == 'undefined' || dom == null) {
      return
    }
    this.loadingView = Loading.service({
      target: dom,
      background: '#ffffff66'
    })
  }

  Vue.prototype.$enterPageInfo = function(id) {
    return {
      'inventory': false,
      'historyInventory': false,
      'location': false,
      'alert': false,
      'emergencySupplies': false,
      'alert_5': false,
      'deviceManager': false,
      'breatheManager': false,
      'workManager': false,
      'deviceBorrow': false,
      'deviceView': false,
      'deviceWorkerHome': false,
      'deviceMQ': false,
      'mapRender': false,
      'orderSend': false,
      'deviceRepair': false,
      'deviceRepairManager': false,
      'z32Config': false,
      'z8Config': false
    }
  }
}
export default install
