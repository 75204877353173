import {
  Loading
} from 'element-ui'
const loading = {}
let timeOut = null
const startLoading = (data) => {
  const serviceData = data || {}
  loading[serviceData.target] = Loading.service({
    lock: serviceData.data || false,
    body: serviceData.body || false,
    fullscreen: serviceData.fullscreen || true,
    text: serviceData.text || '',
    target: serviceData.target || '',
    background: serviceData.background || 'rgba(255, 255, 255, 0.5)',
    customClass: (serviceData.customClass || '') + ' el-loading-' + (serviceData.size && serviceData.size ===
      'mini' ? 'mini' : 'default'),
    spinner: serviceData.spinner || ''
  })
}

export const showLoading = (data) => {
  if (!data.target) return

  const el = document.querySelectorAll(data.target)
  if (!el || !el.length) return

  // 同一个target不能重复添加loading
  if (!loading[data.target]) {
    startLoading(data)
  }

  // 若showLoading后,在等待请求结果的时间内切换页面,导致没执行hideLoading,
  // 使startLoading不能重新执行,则由计时器手动重置loading
  timeOut = setTimeout(() => {
    if (loading[data.target]) {
      hideLoading(data)
    }
  }, 30000)
}

export const hideLoading = (data) => {
  if (!data.closeClearTimeout) {
    clearTimeout(timeOut)
  }
  if (!data) {
    // 若hideLoading无参数传回，则清除全部loading
    for (const key in loading) {
      loading[key].close()
      delete loading[key]
    }
  } else {
    const serviceData = data
    if (loading[serviceData.target]) {
      loading[serviceData.target].close()
    }
    delete loading[serviceData.target]
  }
}


const install = function(Vue) {
	Vue.prototype.$showLoading = showLoading
	Vue.prototype.$hideLoading = hideLoading
}
export default install