import request from '../request/request'

let url = process.env.VUE_APP_OLD_CAT_ASSETURL

export default {
  classList(params) {
    return request({
      url: url + '/api/v1/asset/all/classList',
      method: 'post',
      responseType: 'json',
      params
    })
  },

  findDevice(params) {
    return request({
      url: url + '/api/v1/asset/app/listAssetFuzzy',
      method: 'post',
      responseType: 'json',
      params
    })
  }
}
