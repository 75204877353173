<!--
  - svg使用
  @params  name  [String]  {default:''}  图标名
  @params  className  [String]  {default:''}  svg类名
  @params  size  [String]  {default:'16px'}  svg大小
  @params  width  [String]  {default:''}  自定义宽度
  @params  height  [String]   {default:''}  自定义高度
 -->
<template>
  <svg :class="svgClass" aria-hidden="true" :style="{ width: width ? $changePxToRem(width)  : $changePxToRem(size),
  height: height ? $changePxToRem(height) : $changePxToRem(size) }" v-on="$listeners"
  >
    <use :xlink:href="iconName"/>
  </svg>
</template>

<script>
export default {
  name: 'SvgIcon',
  props: {
    name: {
      type: String,
      required: true
    },
    className: {
      type: String,
      default: ''
    },
    size: {
      type: String,
      default: '16px'
    },
    width: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: ''
    }
  },
  computed: {
    iconName() {
      return `#icon-${this.name}`
    },
    svgClass() {
      if (this.className) {
        return 'svg-icon ' + this.className
      } else {
        return 'svg-icon'
      }
    },
    styleExternalIcon() {
      return {
        mask: `url(${this.name}) no-repeat 50% 50%`,
        '-webkit-mask': `url(${this.name}) no-repeat 50% 50%`
      }
    }
  }
}
</script>

<style scoped>
.svg-icon {
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
}

.svg-external-icon {
  background-color: currentColor;
  mask-size: cover !important;
  display: inline-block;
}
</style>
