<template>
	<div id="app">
		<navigation-bar :navigation-height="navigationHeight" :enter="enter" v-show="showNavigation"
			class="navigationBar" :style="{ '--height': navigationHeight + 'px' }"></navigation-bar>
		<div class="appContentView" :style="{'height': height}">
			<transition name="fade">
				<router-view></router-view>
			</transition>
		</div>

		<Kc-Dialog class="reminder" :visible.sync="showReminder" width="80%">
			<div class="title">您有{{ shouldBackCount }}个设备需要归还</div>
			<div class="title">您有{{ shouldLateCount }}个设备即将到期</div>
			<div slot="footer" class="footer">
				<el-button size="small" @click="showReminder=false">取消</el-button>
				<el-button type="primary" size="small" @click="submit">确认</el-button>
			</div>
		</Kc-Dialog>

		<bottom-bar ref="bottomBar"></bottom-bar>
	</div>
</template>

<script>
	import NavigationBar from '@/views/navigationBar/navigationBar'
	import BottomBar from '@/components/bottemBar/bottomBar'

	export default {
		name: 'App',
		components: {
			BottomBar,
			NavigationBar
		},
		data() {
			return {
				timer: null, // 当需要归还的设备的时间在1小时内 在开启每5分钟提醒
				remindList: [],
				showReminder: false,
				shouldBackCount: 0,
				shouldLateCount: 0,
				navigationHeight: 50,
				enter: 'mainEnter',
				showNavigation: true,
				useMobile: false
			}
		},
		computed: {
			showBottomBar: function() {
				if (this.$route.name == 'login') {
					this.showNavigation = false
				}
				if (this.$valueIsExist(this.$route.meta, 'showBottomBar')) {
					return this.$route.meta['showBottomBar']
				}
				return false
			},
			height: function() {
				if (this.showBottomBar == false && this.showNavigation == false) {
					return '100%'
				}
				let height = (this.showBottomBar ? 50 : 0) + (this.showNavigation ? this.navigationHeight : 0)
				let remHeight = this.$changePxToRem(`${height}px`)
				return `calc(100% - ${remHeight})`
			},
			isMobile: function() {
				if (window.navigator.userAgent.match(
						/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
					)) {
					return true // 移动端
				} else {
					return false // PC端
				}
			}
		},
		watch: {
			'$store.getters.userToken': {
				handler(value) {
					if (this.useMobile) {
						this.showNavigation = false
					} else {
						this.showNavigation = (value != '' ? true : false)
					}
					this.showNavigationStatus()
				},
				immediate: true,
				deep: true
			}
		},
		created() {
			this.getProjectConfig()
		},
		mounted() {
			let useMobile = false
			if (typeof this.$route.query['useMobile'] != 'undefined' && this.$route.query['useMobile'] != null) {
				useMobile = this.$route.query['useMobile']
			}
			this.useMobile = useMobile
			this.$bus.on('weChatType', this.weChatType)
			this.$bus.on('showBottomStatus', this.showBottomStatus)
			this.$bus.on('updateEnter', this.updateEnter)
			this.$bus.on('showNavigationStatus', this.showNavigationStatus)
			this.$bus.on('shouldRemind', this.shouldRemind)
			this.addLocalStorageEventByKey('updateDeviceBorrow', this.shouldRemind)
			this.showNavigationStatus()
		},
		beforeDestroy() {
			this.$bus.off('weChatType', this.weChatType)
			this.$bus.off('showBottomStatus', this.showBottomStatus)
			this.$bus.off('updateEnter', this.updateEnter)
			this.$bus.off('shouldRemind', this.shouldRemind)
			this.removeLocalStorageEvent(this.shouldRemind)
		},
		methods: {
			submit: function() {
				this.$push('deviceBorrow/deviceBorrowRemind', {});
				this.showReminder = false;
			},
			/*
			 * 设备归还提醒
			 */
			shouldRemind: function() {
				this.getDeviceBorrow().then(res => {
					if (res.result == false) {
						return
					}
					this.openRemindTimer()
					this.showRemindView()
				})
			},
			closeRemindTimer: function() {
				if (this.timer == null) {
					return
				}
				clearInterval(this.timer)
				this.timer = null
			},
			openRemindTimer: function() {
				this.closeRemindTimer()
				this.timer = setInterval(() => {
					this.getDeviceBorrow().then(res => {
						if (res.result == false) {
							return
						}
						this.showRemindView()
					})
				}, 5 * 60 * 1000)
			},
			showRemindView: function() {
				this.showReminder = true
			},
			getDeviceBorrow: function() {
				return new Promise(resolve => {
					let postData = {
						queryType: 4,
						efficiency: 1,
						page: 1,
						pageSize: 30
					}
					this.$api.deviceBorrowModule
						.deviceOrderList(postData).then(res => {
							let dataList = this.$getDataValue(res, 'data', [])
							let nowTime = new Date().getTime()
							let shouldBackCount = 0
							let shouldLateCount = 0
							for (let i = 0; i < dataList.length; i++) {
								let info = dataList[i]
								if (typeof info['estimatedEndTime'] == 'undefined' || info[
										'estimatedEndTime'] == null) {
									continue
								}
								let estimatedEndTime = info['estimatedEndTime']
								let count = estimatedEndTime - nowTime
								info['backCount'] = count
								if (count <= 0) {
									shouldBackCount++
								} else if (count > 0 && count <= 24 * 60 * 60 * 1000) {
									shouldLateCount++
								} else {
									continue
								}
							}
							this.shouldBackCount = shouldBackCount
							this.shouldLateCount = shouldLateCount
							resolve({
								result: shouldBackCount > 0 || shouldLateCount > 0
							})
						})
				})
			},

			updateEnter: function(res) {
				this.enter = res.enter
			},
			weChatType: function() {
				this.showNavigation = false
			},
			showNavigationStatus: function() {
				let type = 'web'
				if (typeof localStorage['weChatType'] != 'undefined') {
					type = localStorage['weChatType']
				}
				if (type == 'app') {
					this.navigationHeight = 80
				} else {
					this.navigationHeight = 50
				}

				if (type == 'weChat') {
					this.showNavigation = false
				}

				let enter = 'mainEnter'
				if (this.$valueIsExist(localStorage, 'enter')) {
					enter = localStorage['enter']
				}
				this.enter = enter

			},
			showBottomStatus: function(res) {
				if (typeof this.$refs['bottomBar'] == 'undefined') {
					return
				}
				let index = 0
				if (typeof res['index'] != 'undefined') {
					index = res['index']
				}
				this.$refs['bottomBar'].updateBarList(res.list, index)
			},
			getProjectConfig() {
				this.$api.loginApi.getSettingConfig({
						key: 'projectConfig'
					})
					.then(res => {
						if (res.data) {
							const projectConfig = JSON.parse(res.data)
							this.$store.commit('setPrejectConfig', projectConfig)
						}
					})
					.catch(() => {})
			}
		},
	}
</script>

<style lang="scss" scoped>
	@import "@styles/variables.scss";

	#app {
		left: 0px;
		right: 0px;
		position: absolute;
		width: 100%;
		height: 100%;
		overflow: hidden;

		.navigationBar {
			width: 100%;
			height: var(--height);
		}

		.appContentView {
			width: 100%;
			background-color: $background_color1;
		}

		.reminder {
			.title {
				text-align: center;
				line-height: 25px;
				font-size: 20px;
				padding-top: 10px;
			}

			.footer {
				display: flex;
				width: 100%;
				align-items: center;
				justify-content: center;
			}

		}

	}

	.fade-enter-active,
	.fade-leave-avtive {
		transition: opacity 1s
	}

	.fade-enter,
	.fade-leave-to {
		opacity: 0
	}

	::v-deep .van-popup--bottom.van-popup--round {
		border-radius: 12px;
	}
</style>
