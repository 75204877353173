import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import Vconsole from 'vconsole'
import ElementUI from 'element-ui'
// import 'element-ui/lib/theme-chalk/index.css'
import Vant from 'vant';
// import {
// 	addVant
// } from '@components/addVant'
import components from '@components'
import {
	Loading
} from 'element-ui'
import JSEncrypt from 'jsencrypt'
import bus from '@utils/bus.js'
import {
	createSignature
} from '@/wechatSignature'
import icons from './icons/index.js' // icon
import api from './api'
import variables from '@/styles/variables.scss'
import loading from '@utils/loading.js'
const flutter_sdk = require('@utils/flutter/flutter-sdk')
import rsaEncrypt from '@utils/rsaEncrypt.js'
import common from '@utils/common.js'
import utils from '@utils/utils.js'

import '@/filters'
import '@/styles/element-variables.scss'
import 'vant/lib/index.css';
import '@utils/rem.js'
// import 'bootstrap'
// import 'bootstrap/dist/css/bootstrap.css'
import '@/styles/index.scss'

bus(Vue)
// addVant(Vue)
Vue.use(components)
createSignature(Vue)
Vue.use(icons)
Vue.use(ElementUI)
Vue.use(Vant)
Vue.use(loading)
Vue.use(rsaEncrypt)
Vue.use(common)
Vue.use(utils)

Vue.prototype.$api = api
Vue.prototype.$variables = variables
Vue.prototype.flutter = flutter_sdk.sdk
Vue.prototype.defaultColor = ''


window.onFlutterMessage = function(message) {
	Vue.prototype.flutter.onFlutterMessage(message)
}

let updateWindows = () => {
	let orignSetItem = window.localStorage.setItem;
	window.localStorage.setItem = function(key, newValue) {
		let setItemEvent = new Event("setItemEvent");
		setItemEvent.key = key;
		setItemEvent.newValue = newValue;
		setItemEvent.oldValue = localStorage.getItem(key);
		orignSetItem.apply(this, arguments);
		window.dispatchEvent(setItemEvent);
	}
}
updateWindows();


Vue.config.productionTip = false

let vm = new Vue({
	store,
	router,
	render: h => h(App)
}).$mount('#app')

const vConsole = new Vconsole()

export {
	vm,
	vConsole
}
