import ipConfig from '../api/IPConfig/IPConfig'
window.ipConfig = ipConfig;

const modulesFiles = require.context('./apiModule', true, /.js$/)
const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  let moduleName = modulePath.replace(/^.\/(.*)\.js/, '$1')
  const value = modulesFiles(modulePath)
  moduleName = moduleName.split('/')
  if (moduleName.length > 1) {
    modules[moduleName[0]] = modules[moduleName[0]] ? modules[moduleName[0]] : {}
    modules[moduleName[0]][moduleName[1]] = value.default
  } else {
    modules[moduleName[0]] = value.default
  }
  return modules
}, {})
export default {
  ...modules
}
