const options = [{
    label: '次',
    value: 0,
    unit: '次',
    name: '按次计费'
  },
  {
    label: '时',
    value: 1,
    unit: '时',
    name: '按小时计费'
  },
  {
    label: '天',
    value: 3,
    unit: '天',
    name: '按天计费'
  },
  {
    label: '成本转移',
    value: 2,
    unit: '小时',
    name: '按成本计费'
  },
  {
    label: '天(净值)',
    value: 4,
    unit: '天',
    name: '按净值计费'
  }
]

export function formatBillingType(e) {
  const res = options.filter(item => {
    return item.value == e
  })
  if (res.length) return res[0]
  else return null
}

export default options
