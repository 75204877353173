let filterManager = {
  data() {
    return {
      margin: 5,
      pathInfo: {
        paths: [],
        index: 0
      }
    }
  },
  computed: {
    childrenOpenList() {
      let openList = []
      let itemList = []
      for (let i = 0; i < this.childList.length; i++) {
        let data = this.childList[i]
        let children = []
        if (this.$valueIsExist(data, this.childKey)) {
          children = data[this.childKey]
        }
        if (children.length == 0) {
          itemList.push(data)
        } else {
          openList.push(data)
        }
      }
      return {
        openList: openList,
        itemList: itemList
      }
    },
    lastListWidth() {
      let count = this.showLastList.length
      let totalWidth = count * (60 + this.margin)
      return `calc(100% - ${totalWidth}px)`
    },
    childList() {
      for (let i = this.pathInfo.paths.length - 1; i >= 0; i--) {
        let item = this.pathInfo.paths[i]
        let children = []
        if (this.$valueIsExist(item, this.childKey)) {
          children = item[this.childKey]
        }
        if (children.length == 0) {
          continue
        }
        return children
      }
      return []
    },

    childrenList() {
      if (this.pathInfo.paths.length == 0) {
        return []
      }
      let last = this.lastItem

      let children = []
      if (this.$valueIsExist(last, this.childKey)) {
        children = last[this.childKey]
      }
      if (last.open == true) {
        return children
      }
      return []
    },
    lastItem() {
      if (this.pathInfo.paths.length == 0) {
        let newInfo = {}
        newInfo[this.labelKey] = '全部科室'
        newInfo[this.childKey] = []
        newInfo[this.valueKey] = ''
        newInfo['open'] = false
        return newInfo
      }
      return this.pathInfo.paths[this.pathInfo.paths.length - 1]
    },
    showLastItemNotOpenList() {
      if (this.lastItem['open'] == true) {
        return []
      }

      if (this.pathInfo.paths.length == 0) {
        return []
      }

      if (this.pathInfo.paths.length == 1) {
        return this.pathInfo.paths
      }

      let data = this.pathInfo.paths[this.pathInfo.paths.length - 2]
      return data[this.childKey]
    },
    showLastList() {
      if (this.pathInfo.paths.length == 0) {
        return []
      }
      let length = this.lastItem.open ? this.pathInfo.paths.length : this.pathInfo.paths.length - 1
      let list = this.pathInfo.paths.slice(0, length)

      let count = 3
      if (list.length <= count) {
        return list
      }
      let newList = []
      for (let i = list.length - count; i < list.length; i++) {
        let info = list[i]
        if (i == list.length - count) {
          let newInfo = {}
          newInfo[this.labelKey] = '《'
          newInfo[this.childKey] = info[this.childKey]
          newInfo[this.valueKey] = info[this.valueKey]
          newList.push(newInfo)
        } else {
          newList.push(info)
        }
      }
      return newList
    }
  },
  mounted() {

  },
  methods: {
    getSelectResult: function() {
      let result = {
        result: false
      }

      for (let i = this.pathInfo.paths.length - 1; i >= 0; i--) {
        let info = this.pathInfo.paths[i]
        if (info.checked == false) {
          continue
        }
        result['id'] = info['id']
        result.result = true
        break
      }
      return result
    },
    getResult: function() {
      let result = {
        result: false
      }

      for (let i = this.pathInfo.paths.length - 1; i >= 0; i--) {
        let info = this.pathInfo.paths[i]
        if (info.checked == false) {
          continue
        }
        let children = []
        if (this.$valueIsExist(info, this.childKey)) {
          children = info[this.childKey]
        }

        let find = false

        for (let j = 0; j < children.length; j++) {
          let d = children[j]
          let checked = false
          if (this.$valueIsExist(d, 'checked')) {
            checked = d['checked']
          }
          if (checked == false) {
            continue
          }
          result['id'] = d['id']
          find = true
          break
        }
        if (find == false) {
          result['id'] = info['id']
        }
        result.result = true
        break
      }
      return result
    },
    submit_V2: function() {
      this.$emit('submit', { result: this.getSelectResult(), type: this.typeKey })
    },
    submit: function() {
      this.$emit('submit', { result: this.getResult(), type: this.typeKey })
    },
    cancel: function() {
      this.$emit('cancel', {})
    },
    close: function(item) {
      let findIndex = -1

      for (let i = 0; i < this.pathInfo.paths.length; i++) {
        let info = this.pathInfo.paths[i]
        if (item.id == info.id) {
          findIndex = i
          break
        }
      }

      if (findIndex < 0) {
        return
      }
      let open = false
      if (this.$valueIsExist(item, 'open')) {
        open = !item['open']
      }

      item['open'] = open
      this.pathInfo.paths = this.pathInfo.paths.slice(0, (findIndex + 1))
    },

    handleSelect(item, checked) {

      let updateChildren = (item, dataList) => {
        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          if (data.id != item.id) {
            data['open'] = false
            data['checked'] = false
            continue
          }
          data['open'] = true
          data['checked'] = checked
        }
      }

      let findPath = (item, dataList, list, totalList) => {
        if (item.pid == '') {
          if (list.length == 0) {
            item['open'] = true
            item['checked'] = true
            list.unshift(item)
          }

          return list
        }

        if (dataList.length == 0) {
          return list;
        }

        let children = []

        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          let childrenList = []
          if (this.$valueIsExist(data, this.childKey)) {
            childrenList = data[this.childKey]
          }
          if (data.id == item.pid) {
            data['open'] = true
            data['checked'] = true
            list.unshift(data)
            updateChildren(item, childrenList)
            return findPath(data, totalList, list, totalList)
          }
          children = children.concat(childrenList)
        }
        return findPath(item, children, list, totalList)
      }
      let totalList = JSON.parse(JSON.stringify(this.panelOptions))
      let pathList = findPath(item, totalList, [], totalList)
      this.pathInfo.paths = pathList
      this.filterableValue = ''
    },
    // 点击选项
    checkboxChange(e, item) {
      item['checked'] = e
      this.handleSelect(item, e)
    },
    // 点击展开
    nodeItemMousemoveEvent(panelIndex, item, index) {
      let copyItem = item
      this.handleSelect(copyItem, true)
      let findIndex = -1

      for (let i = 0; i < this.pathInfo.paths.length; i++) {
        let info = this.pathInfo.paths[i]
        if (item.id == info.id) {
          findIndex = i
          break
        }
      }
      copyItem['open'] = true
      copyItem['checked'] = true
      if (findIndex >= 0) {
        this.pathInfo.paths = this.pathInfo.paths.slice(0, (findIndex + 1))
      } else {
        this.pathInfo.paths.push(copyItem)
      }
    },
    // 点击展开
    pageNodeItemMousemoveEvent(panelIndex, item, index) {
      let copyItem = item
      let findIndex = -1

      for (let i = 0; i < this.pathInfo.paths.length; i++) {
        let info = this.pathInfo.paths[i]
        if (item.id == info.id) {
          findIndex = i
          break
        }
      }
      copyItem['open'] = false
      copyItem['checked'] = true

      let children = []
      if (this.$valueIsExist(copyItem, this.childKey)) {
        children = copyItem[this.childKey]
      }

      if (children.length > 0) {
        if (findIndex >= 0) {
          this.pathInfo.paths = this.pathInfo.paths.slice(0, (findIndex + 1))
        } else {
          this.pathInfo.paths.push(copyItem)
        }
        return
      }

      if (findIndex >= 0) {
        this.pathInfo.paths = this.pathInfo.paths.slice(0, (findIndex + 1))
        return
      }
      if (this.pathInfo.paths.length == 0) {
        this.pathInfo.paths.push(copyItem)
        return
      }

      let last = this.pathInfo.paths[this.pathInfo.paths.length - 1]
      let lastChildren = []
      if (this.$valueIsExist(last, this.childKey)) {
        lastChildren = last[this.childKey]
      }
      if (lastChildren.length > 0) {
        this.pathInfo.paths.push(copyItem)
        return
      }
      this.pathInfo.paths = this.pathInfo.paths.slice(0, this.pathInfo.paths.length - 1)
      this.pathInfo.paths.push(copyItem)
    }
  }
}

export default filterManager