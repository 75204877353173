import JSEncrypt from './jsencrypt.min.js'

// rsa加密
export const rsaEncrypt = function(key, str) {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(key)
  const data = encryptor.encrypt(str.toString())
  return data
}

// rsa解密
export const rsaDecrypt = function(key, str) {
  const decrypt = new JSEncrypt()
  decrypt.setPrivateKey(key)
  const data = decrypt.decrypt(str.toString())
  return data
}

const install = function(Vue) {
  Vue.prototype.$rsaEncrypt = rsaEncrypt
  Vue.prototype.$rsaDecrypt = rsaDecrypt
}

export default install
