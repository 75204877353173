import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}

export default {
  updateDeviceImage(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/image/addImageFromUrl',
      method: 'post',
      data
    })
  },
  updateDevice(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/addOrUpdate',
      method: 'post',
      data
    })
  },
  imageUpload(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/image/upload',
      method: 'post',
      data
    })
  },
  getDeviceDetail(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/deviceDetail',
      method: 'post',
      data
    })
  },
  getDeviceTypeCount(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/countByType',
      method: 'post',
      data
    })
  }

}
