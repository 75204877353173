<template>
  <div class="navigationBarView"
       :style="{'padding-top':barStyle.paddingTop,'border-bottom': barStyle.borderBottom, 'background-color':barStyle.backgroundColor}"
  >
    <div v-show="showBack" class="imgView" @click="goBack">
      <svg-icon class="icon" :style="{color:barStyle.iconColor}" width="25px" height="25px" name="arrow-left"
      ></svg-icon>
    </div>
    <div :class="{'titleView':showBack, 'fullTitle':!showBack} " :style="{color:barStyle.titleColor}">
      {{ headerTitle }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'navigationBar',
  props: {
    enter: {
      type: String,
      default: ''
    },
    navigationHeight: {
      default: 0
    }
  },
  watch: {
    navigationHeight: {
      handler(value) {
        let type = 'web'
        if (typeof localStorage['weChatType'] != 'undefined') {
          type = localStorage['weChatType']
        }
        if (type == 'app') {
          this.barStyle = {
            backgroundColor: '#3C85F6',
            iconColor: 'white',
            titleColor: 'white',
            borderBottom: 'none',
            paddingTop: '35px'
          }
          return
        }
        this.barStyle = {
          backgroundColor: 'white',
          iconColor: 'black',
          titleColor: 'black',
          borderBottom: '1px #cccccc solid',
          paddingTop: '0px'
        }
      },
      immediate: true
    }
  },
  computed: {
    headerTitle() {
      return this.$route.meta.title
    },
    showBack() {
      let path = 'mainEnter'
      if (this.$valueIsExist(this.routerInfo, this.enter)) {
        path = this.routerInfo[this.enter]
      }
      if (this.$route.name == path) {
        return false
      }
      return true
    }

  },
  data() {
    return {
      barStyle: {
        backgroundColor: 'white',
        iconColor: 'black',
        titleColor: 'black',
        borderBottom: '1px #cccccc solid',
        paddingTop: '0px'
      },
      routerInfo: {
        workManager: 'work_page',
        deviceBorrow: 'deviceBorrow_homeBorrow',
        deviceView: 'deviceView_deviceList'
      }
    }
  },
  mounted() {

  },
  methods: {
    goBack: function() {
      this.$router.go(-1)
    }
  }
}
</script>

<style lang="scss" scoped>

.navigationBarView {
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;

  .imgView {
    width: 25px;
    height: 25px;
  }

  .titleView {
    width: calc(100% - 40px);
    transform: translate(15px, 0px);
    text-align: center;
    font-size: 18px;
    padding-right: 50px;
  }

  .fullTitle {
    width: 100%;
    text-align: center;
    font-size: 18px;
  }


}

</style>
