import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  getPersonList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/spec/patient/cardList',
      method: 'post',
      data
    })
  },

}
