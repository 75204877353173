import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  // 审批人看的统计
  shareCount2(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/count2',
      method: 'post',
      data
    })
  },
  // 设备详情
  shareDeviceDetail(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/deviceDetail',
      method: 'post',
      data
    })
  },
  // 今日共享概览
  deviceShareOverview(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceShareOverview',
      method: 'post',
      data
    })
  },
  // 设备加入Or移除 共享池
  deviceAddOrMoveShare(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceAddOrMoveShare',
      method: 'post',
      data
    })
  },
  // 设备共享 审批
  deviceShareApprove(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceShareApprove',
      method: 'post',
      data
    })
  },
  // 共享收益图
  deviceShareChart(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceShareChart',
      method: 'post',
      data
    })
  },
  // 年统计
  deviceShareYearCount(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceShareYearCount',
      method: 'post',
      data
    })
  },
  // 可加入共享池的 设备列表
  shareDeviceList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceList',
      method: 'post',
      data
    })
  },
  // 共享池web页面列表 包含共享次数 收益
  shareListForWeb(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/shareListForWeb',
      method: 'post',
      data
    })
  },
  // 科室配置 
  sharePoolConfig(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/sharePoolConfig',
      method: 'post',
      data
    })
  },
  // 订单记录
  orderList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/orderList',
      method: 'post',
      data
    })
  },
  // 订单流程记录
  orderDataInfo(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/orderDetail',
      method: 'post',
      data
    })
  },
  // 获取设备折旧金额
  deviceZj(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/deviceZj',
      method: 'get',
      params,
    })
  }
}
