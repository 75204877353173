<template>
	<filterPopupItem :label="label">
		<div class="filterPopupCascaderItem">1</div>
		<KcCascader ref="kcCascader" v-model="value1" v-on="$listeners" v-bind="$attrs" @change="onChange"></KcCascader>
	</filterPopupItem>
</template>

<script>
export default {
	name: 'filterPopupCascaderItem',
	components: {},
	model: {
		prop: 'value',
		event: 'changeValue'
	},
	props: {
		value: {
			type: [String, Number, Array, Object],
			default: ''
		},
		label: {
			type: [String, Number],
			default: '科室：'
		}
	},
	data() {
		return {
			value1: ''
		};
	},
	watch: {
		value: {
			handler() {
				this.value1 = this.value;
				this.getResultList()
			},
			immediate: true
		}
	},
	created() {},
	mounted() {
		this.$parent.$emit('filterPopupItem.addField', this)
	},
	methods: {
		onChange(a, b, c) {
			this.$emit('changeValue', this.value1);
			this.$emit('change', a, b, c);
		},
		getResultList(){
			this.$nextTick(()=>{
				if(this.$attrs.options.length){
					console.log(this.$refs.kcCascader.getResultList())
				}
			})
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.filterPopupCascaderItem {
}
</style>
