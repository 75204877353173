<template>
  <div class="bottomBarItem" :style="{width:width}" @click="selectItem">
    <svg-icon :class="{unactive:!select, active:select}" v-if="itemInfo.svg!=''" width="20px" height="20px" :name="svgName"></svg-icon>
    <div class="title" :class="{unactive:!select, active:select}">{{ itemInfo.name }}</div>
  </div>
</template>

<script>
export default {
  name: 'bottomBarItem',
  computed:{
    svgName() {
      if (this.$valueIsExist(this.itemInfo, 'svg') == false) {
        return ''
      }
      return `${this.select ? 'active-bottom-' : 'unActive-bottom-'}${this.itemInfo.svg}`
    },
  },
  props: {
    index:{
      default:0,
    },
    select:{
      default:true,
    },
    width: {
      type: String,
      default: '100%'
    },
    itemInfo: {
      type: Object,
      default: () => {
        return {
          svg: '',
          name: '',
          position: ''
        }
      }
    }
  },
  methods:{
    selectItem:function() {
      this.$emit('selectItem', {index:this.index})
      this.$bus.emit('selectItem', {index:this.index})
    },
  }

}
</script>

<style lang="scss" scoped>
@import "@styles/variables.scss";

.bottomBarItem {
  float: left;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .icon {
    width: 20px;
    height: 20px;
  }

  .unactive {
    color: $color3;
  }

  .active {
    color: $color_primary;
  }

  .title {
    margin-left: 3px;
    font-size: 15px;
    text-align: center;
  }
}

</style>
