import request from '../request/request'

function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}
export default {
  RoleListByUser: function(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/role/RoleListByUser',
      method: 'post',
      data
    })
  },
  // 地图详情
  getMapDetail(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/svg/map/detail',
      method: 'post',
      data,
    })
  },
  getOrgMapDetail(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/svg/map/data',
      method: 'post',
      data
    })
  },
  // 获取建筑列表
  getBiuldingList(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/svg/building/list',
      method: 'post',
      data
    })
  },
  getUserInfo(params) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/userLoginMessage',
      method: 'get',
      responseType: 'json',
      params
    })
  },
  // 租户树形列表 权限版本
  getListTreeTenantAuthority(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/tenantCustomer/listTreeTenantAuthority',
      method: 'post',
      responseType: 'json',
      data
    })
  },
  //维修工程师用户列表
  userListForRepair(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/userListForRepair',
      method: 'post',
      data
    })
  },
  // 添加用户
  addUser(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/addOrUpdateUser',
      method: 'post',
      data
    })
  },
  // 用户绑定的角色
  userBindRole(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/role/RoleListByUser',
      method: 'post',
      data
    })
  },
  // 角色列表
  getRoleList(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/role/roleList',
      method: 'post',
      data
    })
  },
  // 用户绑定角色
  setUserBindingRole(params) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/operatorUserRole',
      method: 'get',
      params
    })
  },
  // 删除账号
  deleteUser(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/deletedOrNotUser',
      method: 'post',
      data
    })
  }
}
