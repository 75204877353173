<!--
  - 弹窗组件
  @params  visible  [Boolean]  {default:false}  弹窗状态(用.sync修饰符)
  @params  title  [String]  {default:''}  弹窗标题
  @params  width  [String]  {default:''}  弹窗自定义宽度
  @params  height  [String]   {default:''}  弹窗自定义高度
  @params  minWidth  [String]  {default:''}  弹窗自定义最小宽度
  @params  minHeight  [String]   {default:''}  弹窗自定义最小高度
  @params  beforeClose  [Function]  {default:()=>{}}  关闭前回调函数(返回真则关闭)
  @params  modelClose  [Boolean]  {default:false}  点击遮罩层关闭弹窗
  @params  showClose  [Boolean]  {default:true}  是否显示关闭按钮
  @params  dialogClass  [String]   {default:''}  弹窗自定义类名
  @event   close  关闭回调函数
 -->
<template>
  <transition name="kcDialog_wrapper_fade">
    <div v-show="visible" ref="kcDialog_wrapper" class="kcDialog_wrapper" @click.stop="modelCloseEvent">
      <transition name="kcDialog_fade">
        <div v-if="visible" class="kcDialog" :class="dialogClass" :style="kcDialogStyle" @click.stop="">
          <div v-if="showClose" class="kcDialog_closeBtn center" title="关闭" @click="closeEvent"><i class="el-icon-close" /></div>
          <div v-if="title" class="kcDialog_header">
            <p>{{ title }}</p>
          </div>
          <div class="kcDialog_body" :class="kcDialogBodyClass"><slot /></div>
          <div v-if="$slots.footer" class="kcDialog_footer"><slot name="footer" /></div>
        </div>
      </transition>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'KcDialog',
  components: {},
  props: {
    visible: {
      // 弹窗状态(用.sync修饰符)
      type: Boolean,
      default: false
    },
    title: {
      // 弹窗标题
      type: String,
      default: ''
    },
    width: {
      // 弹窗自定义宽度
      type: [String, Number],
      default: ''
    },
    height: {
      // 弹窗自定义高度
      type: [String, Number],
      default: ''
    },
    minWidth: {
      type: [String, Number],
      default: ''
    },
    minHeight: {
      type: [String, Number],
      default: ''
    },
    beforeClose: {
      // 关闭回调
      type: [Function, String, Object],
      default: ''
    },
    modelClose: {
      // 是否点击蒙层关闭弹窗
      type: Boolean,
      default: false
    },
    showClose: {
      // 是否显示关闭按钮
      type: Boolean,
      default: true
    },
    dialogClass: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {}
  },
  computed: {
    kcDialogStyle() {
      // 设置样式
      return {
        width: this.width,
        height: this.height,
        minWidth: this.minWidth,
        minHeight: this.minHeight
      }
    },
    kcDialogBodyClass() {
      return {
        kcDialog_body_have_footer: this.$slots.footer && !this.title,
        kcDialog_body_have_title: this.title && !this.$slots.footer,
        kcDialog_body_have_title_footer: this.$slots.footer && this.title
      }
    }
  },
  watch: {
    visible() {
      if (!this.visible) this.setClose()
    }
  },
  created() {},
  mounted() {},
  methods: {
    closeEvent() {
      if (!this.beforeClose) {
        this.setClose()
      } else if (typeof this.beforeClose === 'function') {
        const before = this.beforeClose()
        if (before && before.then) {
          before
            .then(() => {
              this.setClose()
            })
            .catch(() => {})
        } else if (before) {
          this.setClose()
        }
      }
    },
    setClose() {
      this.$emit('update:visible', false)
      this.$emit('close')
    },
    modelCloseEvent() {
      // 点击遮罩关闭
      if (this.modelClose) {
        this.closeEvent()
      }
    }
  }
}
</script>

<style lang="scss" scoped="scoped">
.kcDialog_wrapper {
  z-index: 1003;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);

  .kcDialog {
    background: #ffffff;
    position: relative;
    border-radius: 4px;
    box-shadow: 4px 4px 24px 0px rgba(160, 170, 199, 0.4);
    transition: all 0.3s linear;
    display: flex;
    flex-direction: column;
  }
  .kcDialog_closeBtn {
    position: absolute;
    top: 6px;
    right: 8px;
    border-radius: 50%;
    color: #ababab;
    text-align: center;
    font-size: 16px;
    cursor: pointer;
    &:hover {
      background: #bbb;
    }
  }
  .kcDialog_header {
    padding: 20px;
    padding-bottom: 0px;
    height: 52px;
    p {
      padding-bottom: 9.5px;
      font-size: 20px;
      color: #333;
      font-weight: 900;
      text-align: center;
    }
  }
  .kcDialog_body {
    padding: 16px;
    color: #606266;
    font-size: 16px;
    &.kcDialog_body_have_title {
      height: calc(100% - 52px);
    }
    &.kcDialog_body_have_footer {
      height: calc(100% - 62px);
    }
    &.kcDialog_body_have_title_footer {
      height: calc(100% - 114px);
    }
  }
  .kcDialog_footer {
    padding: 20px;
    padding-top: 10px;
    display: flex;
    text-align: right;
    > * {
      width: 100%;
    }
  }
}

// 遮罩
.kcDialog_wrapper_fade-enter-active,
.kcDialog_wrapper_fade-leave-active {
  transition: opacity 0.5s;
}
.kcDialog_wrapper_fade-enter,
.kcDialog_wrapper_fade-leave-to {
  opacity: 0;
}
// 内容
.kcDialog_fade-enter-active {
  animation: kcDialog_fadeIn 0.5s ease-out;
}
.kcDialog_fade-leave-active {
  animation: kcDialog_fadeOut 0.5s ease-out;
}

.kcDialog_fade-enter-active,
.kcDialog_fade-leave-active {
  transform: scale(1);
}
.kcDialog_fade-enter,
.kcDialog_fade-leave-to {
  transform: scale(0.5);
}
@keyframes kcDialog_fadeIn {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes kcDialog_fadeOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.5);
  }
}
</style>
