import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}

export default {
  // 借用人评论
  repairComment2(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/comment2',
      method: 'post',
      data
    })
  },
  // 设备科评论
  repairComment1(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/comment1',
      method: 'post',
      data
    })
  },
  // 工程师审核
  repairCheck(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/check',
      method: 'post',
      data
    })
  },
  // 工程师完成维修
  repairFinish(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/finish',
      method: 'post',
      data
    })
  },
  // 工程师开始维修
  startRepair(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/startRepair',
      method: 'post',
      data
    })
  },
  // 工程师达到现场
  reportArrive(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/arrive',
      method: 'post',
      data
    })
  },
  // 工程师拒绝
  reportDeny(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/deny',
      method: 'post',
      data
    })
  },
  // 报障列表
  reportList(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/reportList',
      method: 'post',
      data
    })
  },
  // 催单
  remindReport(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/remindReport',
      method: 'post',
      data
    })
  },
  // 响应报障
  answerReport(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/answerReport',
      method: 'post',
      data
    })
  },
  // 取消报修
  reportCancel(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/cancel',
      method: 'post',
      data
    })
  },
  // 取消报修
  reportAssign(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/assign',
      method: 'post',
      data
    })
  },
  //报障订单流程和报障信息
  reportDataInfo(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/dataInfo',
      method: 'post',
      data
    })
  },
  //维修处理
  reportHandle(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/handle',
      method: 'post',
      data
    })
  },
  //维修审核
  reportExamine(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/examine',
      method: 'post',
      data
    })
  },
  //维修工程师用户列表
  userListForRepair(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/userListForRepair',
      method: 'post',
      data
    })
  },
  // 添加用户
  addUser(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/addOrUpdateUser',
      method: 'post',
      data
    })
  },
  // 用户绑定的角色
  userBindRole(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/role/RoleListByUser',
      method: 'post',
      data
    })
  },
  // 角色列表
  getRoleList(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/role/roleList',
      method: 'post',
      data
    })
  },
  // 用户绑定角色
  setUserBindingRole(params) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/operatorUserRole',
      method: 'get',
      params
    })
  },
  // 删除账号
  deleteUser(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/user/deletedOrNotUser',
      method: 'post',
      data
    })
  }
}
