import request from '../request/request'

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  // 设备科看的统计
  shareCount1(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/share/count1',
      method: 'post',
      data
    })
  },
  // 设备科管理角色报障维修统计
  reportCount1(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/report/count1',
      method: 'post',
      data
    })
  },
  // 共享订单列表
  shareOrderListByStatus(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/shareOrderListByStatus',
      method: 'post',
      data
    })
  },
  // 共享池 设备借用收益统计
  sharePoolIncomeCount(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/sharePoolIncomeCount',
      method: 'post',
      data
    })
  },

  // 设备科首页共享调度概览图
  countForTenantByStatus(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/countForTenantByStatus',
      method: 'post',
      data
    })
  },
  // 设备科借入借出柱状图
  countForTenantBorrow(data) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/device/share/countForTenantBorrow',
      method: 'post',
      data
    })
  }

}
