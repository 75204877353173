import Vue from 'vue'

import SvgIcon from './SvgIcon'
import KcDialog from './KcDialog'
import filterPanel from "./filterPanel/index";
import FilterPanelItem from "./filterPanel/FilterPanelItem.vue";
import searchInput from "./searchInput";
import KcCascader from "./KcCascader";
import filterPopup from "./filterPopup";
import filterPopupItem from "./filterPopup/filterPopupItem.vue";
import filterPopupCascaderItem from "./filterPopup/filterPopupCascaderItem.vue";
import KcEmpty from "./KcEmpty";
import tabs1 from "./tabs1";

const install = function(Vue) {
	Vue.component('SvgIcon', SvgIcon)
	Vue.component('KcDialog', KcDialog)
	Vue.component('filterPanel', filterPanel)
	Vue.component('FilterPanelItem', FilterPanelItem)
	Vue.component('searchInput', searchInput)
	Vue.component('KcCascader', KcCascader)
	Vue.component('filterPopup', filterPopup)
	Vue.component('filterPopupItem', filterPopupItem)
	Vue.component('filterPopupCascaderItem', filterPopupCascaderItem)
	Vue.component('KcEmpty', KcEmpty)
	Vue.component('tabs1', tabs1)
}
export default install