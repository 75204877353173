<!-- 使用文档
	参数:
		value/v-model - 绑定值
		options - 选项列表
		height - 选择框高度
		searchPlaceholder - 搜索框提示语
		clearable - 是否显示清空按钮，默认true
		filterable - 是否显示搜索框，默认true
		showBreadcrumb - 是否显示选择导航条，默认true
		isChild - 包含子级值，默认true
		showIsChild - 是否显示包含子级选项，默认false
		isChildLabel - 子级选项提示
		defaultValue - 默认值，默认''
		props - 配置项（配置绑定参数等）
		
	事件:
		cancel - 点击取消事件
		submit - 点击确定事件（同时触发绑定参数更新事件）
			参数1：选中值
			参数2：选中结果值（包含id和name）
		close - 弹窗关闭事件
	方法:
		show - 打开弹窗
		hide - 关闭弹窗
		getResultList - 获取选中结果数组（返回选中结果值，包含id和name）
 -->



<template>
	<van-popup v-model="visible" position="top" get-container="body" :style="{ height: height }" class="KcCascader" :class="KcCascaderClass" @close="popupClose">
		<div class="KcCascader-header">
			<div v-if="filterable" class="srarch-wrap">
				<div class="search-input">
					<van-search v-model="query" :placeholder="searchPlaceholder" @input="searchOnInput" @clear="searchOnClear" @search="searchOnSearch" />
				</div>
				<button class="search-btn" type="button" @click="searchOnSearch">搜索</button>
			</div>

			<div v-if="showBreadcrumb" class="select-breadcrumb" :class="{ 'select-breadcrumb-have-clear': clearable }">
				<div class="select-breadcrumb-list-wrap">
					<div class="select-breadcrumb-list" ref="selectBreadcrumbList">
						<template v-for="(item, index) in selectBreadcrumb">
							<div v-if="index" class="list-arrow" :key="'arrow' + index"><van-icon name="arrow" /></div>
							<div class="list-item" :key="'item' + index" @click="breadcrumbEvent(item)">{{ item.name }}</div>
						</template>
					</div>
				</div>

				<button type="button" v-if="clearable && selectBreadcrumb.length" class="clear-breadcrumb-btn" @click="clearableEvent"><van-icon name="clear" /></button>
			</div>
		</div>
		
		
		<div class="KcCascader-content" :style="{ height: contentHeight }">
			<ul class="content-list">
				<li v-if="showIsChild" class="content-item is-child-item" :class="{ 'check-is-child-item': currentIsChild }" @click="isChildEvent">
					<span class="label">{{ isChildLabel }}</span>
					<van-icon :name="currentIsChild ? 'checked' : 'passed'" />
				</li>
				<li class="content-item" :class="{ active: currentValue == item[propsConfig.value] }" v-for="(item, index) in listOptions" :key="index" @click="itemEvent(item)">
					<span class="label">{{ item[propsConfig.label] }}</span>
					<van-icon v-if="item[propsConfig.children] && item[propsConfig.children].length" name="arrow" />
				</li>
			</ul>
		</div>
		
		
		<div class="KcCascader-footer">
			<button type="button" class="cancel-btn" @click="cancelEvent">取消</button>
			<button type="button" class="submit-btn" @click="submitEvent">确定</button>
		</div>
	</van-popup>
</template>

<script>
import { debounce } from '../searchInput/debounce.js';
export default {
	name: 'KcCascader',
	components: {},
	model: {
		prop: 'value',
		event: 'changeValue'
	},
	props: {
		value: {
			type: [String, Number, Array, Object],
			default: ''
		},
		// options未设置deep监听  需要整参赋值才能触发到选项更新
		options: {
			type: Array,
			default: () => {
				return [];
			}
		},
		height: {
			type: String,
			default: 'calc(100% - 50px)'
		},
		searchPlaceholder: {
			type: [String, Number, Object],
			default: '搜索'
		},
		// 是否要清空按钮
		clearable: {
			type: Boolean,
			default: true
		},
		// 是否要过滤器(搜索)（数量多时建议使用）
		filterable: {
			type: Boolean,
			default: true
		},
		// 是否显示面包屑导航（多级时建议使用，非多级建议取消）
		showBreadcrumb: {
			type: Boolean,
			default: true
		},
		// 是否显示底部按钮
		showFooter: {
			type: Boolean,
			default: true
		},
		// 使用.sync修饰
		isChild: {
			type: Boolean,
			default: true
		},
		// 是否显示包含子级选项
		showIsChild: {
			type: Boolean,
			default: false
		},
		isChildLabel: {
			type: String,
			default: '包含子级'
		},
		defaultValue: {
			type: [String, Number, Array, Object],
			default: ''
		},
		props: {
			type: Object,
			default: () => {
				return {
					value: 'value',
					label: 'label',
					children: 'children'
				};
			}
		}
	},
	data() {
		return {
			visible: false,
			
			currentValue:'',
			currentIsChild:true,

			query: '',
			selectBreadcrumb: [],
			cascaderOptions: [],
			listOptions: []
		};
	},
	computed: {
		propsConfig() {
			const props = this.props;
			return {
				value: props.value || 'value',
				label: props.label || 'label',
				children: props.children || 'children'
			};
		},
		KcCascaderClass() {
			return {
				'KcCascader-have-search': this.filterable,
				'KcCascader-have-breadcrumb': this.showBreadcrumb,
				'KcCascader-have-footer': this.showFooter
			};
		},
		contentHeight() {
			let num = 0;
			if (this.filterable) num += 40;
			if (this.showBreadcrumb) num += 40;
			if (this.showFooter) num += 40;
			
			return `calc(100% - ${num/16}rem)`;
		}
	},
	watch: {
		options: {
			handler() {
				const _options = JSON.parse(JSON.stringify(this.options));
				const fn = (list, itemPath) => {
					list.map(item => {
						const _itemPath = JSON.parse(JSON.stringify(itemPath));
						_itemPath.push({
							id: item[this.propsConfig.value],
							name: item[this.propsConfig.label]
						});
						item.itemPath = _itemPath;
						if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
							fn(item[this.propsConfig.children], _itemPath);
						}
					});
				};
				fn(_options, []);
				this.cascaderOptions = _options;
			},
			immediate: true
		},
	},
	created() {},
	mounted() {},
	methods: {
		show() {
			this.visible = true;
			
			this.currentValue=this.value
			this.currentIsChild=this.isChild

			this.query = '';
			this.formatSelectBreadcrumb();
			this.formatListOptions();
		},
		hide() {
			this.visible = false;
		},

		formatSelectBreadcrumb() {
			let  haveValue=false
			const fn = list => {
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					if (item[this.propsConfig.value] == this.currentValue) {
						this.selectBreadcrumb = JSON.parse(JSON.stringify(item.itemPath));
						haveValue=true
						this.$nextTick(() => {
							this.$refs.selectBreadcrumbList.scrollLeft = this.$refs.selectBreadcrumbList.scrollWidth;
						});
						break
					} else if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
						fn(item[this.propsConfig.children]);
					}
				}
			};
			fn(this.cascaderOptions);
			
			if(!haveValue) this.selectBreadcrumb = [];
			
		},
		formatListOptions() {
			const fn = list => {
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					if (item[this.propsConfig.value] == this.currentValue) {
						this.listOptions = JSON.parse(JSON.stringify(list));
						return;
					} else if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
						fn(item[this.propsConfig.children]);
					}
				}
			};
			fn(this.cascaderOptions);
			this.listOptions = JSON.parse(JSON.stringify(this.cascaderOptions));
		},

		searchOnInput(e) {
			this.searchDebounceChange();
		},
		searchDebounceChange: debounce(function() {
			this.searchEvent();
		}, 1000),
		searchOnClear() {
			this.searchDebounceChange(1);
			this.searchEvent();
		},
		searchOnSearch() {
			this.searchDebounceChange(1);
			this.searchEvent();
		},
		searchEvent() {
			if (!this.query) {
				this.formatListOptions();
			} else {
				// 搜索
				const query = this.query;
				const arr = [];
				const fn = list => {
					list.forEach(item => {
						if (item[this.propsConfig.label] && item[this.propsConfig.label].includes(query)) {
							const _item = JSON.parse(JSON.stringify(item));
							if (_item[this.propsConfig.children]) delete _item[this.propsConfig.children];
							arr.push(_item);
						}
						if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
							fn(item[this.propsConfig.children]);
						}
					});
				};
				fn(this.cascaderOptions);
				this.listOptions = arr;
			}
		},

		clearableEvent() {
			if (this.defaultValue == this.currentValue) return;
			this.currentValue=this.defaultValue

			this.$nextTick(() => {
				this.formatSelectBreadcrumb();
				this.formatListOptions();
			});
		},
		breadcrumbEvent(item) {
			if (item[this.propsConfig.value] != this.currentValue) {
				this.currentValue=item[this.propsConfig.value]
				this.$nextTick(() => {
					this.formatSelectBreadcrumb();
					this.formatListOptions();
				});
			}
		},
		isChildEvent() {
			this.currentIsChild=!this.currentIsChild
		},
		itemEvent(item) {
			if (item[this.propsConfig.value] != this.currentValue) {
				this.currentValue=item[this.propsConfig.value]
				this.$nextTick(() => {
					this.formatSelectBreadcrumb();
				});
			}

			if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
				this.listOptions = JSON.parse(JSON.stringify(item[this.propsConfig.children]));
			}
		},
		
		
		popupClose(){
			this.$emit('close')
		},

		cancelEvent() {
			this.hide();
			this.$emit('cancel');
		},
		submitEvent() {
			this.$emit('changeValue', this.currentValue);
			this.$emit('update:isChild', this.currentIsChild)
			
			this.$nextTick(()=>{
				this.hide();
				const labelResult = this.getResultList();
				const label = labelResult.length ? labelResult[0] : '';
				
				this.$emit('submit', this.currentValue, label);
			})
		},

		getResultList(value) {
			/**外部方法 - 选择结果列表
			 * @return {array} 选择结果列表 [{id:'',name:''}]
			 */
			if (!this.options.length) {
				return;
			}
			
			const arr = [];
			const fn = list => {
				for (let i = 0; i < list.length; i++) {
					const item = list[i];
					if (item[this.propsConfig.value] == this.value) {
						arr.push({
							id: item[this.propsConfig.value],
							name: item[this.propsConfig.label]
						});
						break;
					} else if (item[this.propsConfig.children] && item[this.propsConfig.children].length) {
						fn(item[this.propsConfig.children]);
					}
				}
			};
			fn(this.cascaderOptions);
			return arr;
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.KcCascader {
	.KcCascader-header {
		background: #f5f7f9;
		.srarch-wrap {
			height: 40px;
			padding-top: 8px;
			display: flex;
			align-items: center;
			.search-input {
				width: calc(100% - 60px);
				padding-left: 16px;
				.van-search {
					padding: 0;
					.van-cell {
						padding: 3px 8px 3px 0;
					}
					.van-search__content {
						border-radius: 19px;
						border: 1px solid #3e73fb;
						background: #fff;
					}
					.van-icon-search {
						color: #ccc;
					}
				}
			}
			.search-btn {
				line-height: 32px;
				background: none;
				color: #3a80f6;
				font-size: 14px;
				width: 60px;
				text-align: center;
			}
		}

		.select-breadcrumb {
			padding: 10px 0px;
			width: calc(100% - 32px);
			margin-left: 16px;
			font-size: 14px;
			color: #666;
			display: flex;
			align-items: center;
			justify-content: space-between;
			line-height: 20px;
			height: 40px;
			&.select-breadcrumb-have-clear {
				.select-breadcrumb-list-wrap {
					width: calc(100% - 24px);
				}
			}
			.select-breadcrumb-list-wrap {
				width: 100%;
				height: 100%;
				overflow: hidden;
			}
			.select-breadcrumb-list {
				width: 100%;
				height: 30px;
				overflow-x: auto;
				white-space: nowrap;
				.list-arrow {
					margin: 0 4px;
					display: inline-block;
				}
				.list-item {
					display: inline-block;
					&:last-child {
						color: #3a80f6;
					}
				}
			}
			.clear-breadcrumb-btn {
				background: none;
				.van-icon {
					font-size: 16px;
					color: #aaa;
				}
			}
		}
	}

	.KcCascader-content {
		background: #fff;
		overflow-y: auto;
		.content-list {
			.content-item.is-child-item {
				&.check-is-child-item {
					.label {
						color: #3a80f6;
					}
				}
				.van-icon {
					font-size: 16px;
					color: #ccc;
					&.van-icon-checked {
						color: #3a80f6;
					}
				}
			}
			.content-item {
				line-height: 18px;
				padding: 10px 12px;
				color: #333333;
				display: flex;
				align-items: center;
				justify-content: space-between;
				font-size: 14px;
				&.active {
					.label {
						color: #3a80f6;
					}
				}
				.label {
					color: #333333;
					width: calc(100% - 20px);
				}
				.van-icon {
					color: #999;
				}
			}
		}
	}

	.KcCascader-footer {
		height: 40px;
		display: flex;
		justify-content: space-around;
		padding: 0 12px;
		padding-bottom: 8px;
		button {
			line-height: 32px;
			width: 40%;
			text-align: center;
			border-radius: 32px;
			color: #3a80f6;
			border: 1px solid #3a80f6;
			cursor: pointer;
			font-size: 14px;
		}
		.cancel-btn {
			background: #fff;
		}
		.submit-btn {
			background: #3a80f6;
			color: #fff;
		}
	}
}
</style>
