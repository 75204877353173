let onBluetoothDeviceCallback;
let onQRCodeCallback;
let onConnectStatusCallback;
let onSocketMsgCallback;
let onSocketStatusCallback;
let onNetworkInfoCallback;
let sdk = {

    //////////////////////////////////////////////////////
    //js调用flutter
    //////////////////////////////////////////////////////

    //开启蓝牙扫描
    openBluetooth: function () {
        const data = {method: "openBluetooth"};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //关闭蓝牙扫描
    closeBluetooth: function () {
        const data = {method: "closeBluetooth"};
        this.postMessageToFlutter(JSON.stringify(data));
        onBluetoothDeviceCallback = null;
    },
    //连接蓝牙设备
    connectBT: function (address) {
        const data = {method: "connectBT", data: address};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //断开蓝牙连接
    disconnectBT: function () {
        const data = {method: "disconnectBT"};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //修改beacon
    ///{
    //   "uuid": "",
    //   "major": 1,
    //   "minor": 1,
    //   "broadCastInterval": 1,
    //   "testPower": 1,
    //   "transPower": 1,
    // }
    updateBT: function (beacon) {
        const data = {method: "updateBT", data: beacon};
        this.postMessageToFlutter(JSON.stringify(data));
    },

    //扫二维码
    openQRCode: function () {
        const data = {method: "openQRCode"};
        this.postMessageToFlutter(JSON.stringify(data));
    },

    //打开socket
    openSocket: function (host, port) {
        const data = {method: "openSocket", data: {host: host, port: port}};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //关闭socket
    closeSocket: function () {
        const data = {method: "closeSocket"};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //发送socket消息
    socketSend: function (msg) {
        const data = {method: "socketSend", data: msg};
        this.postMessageToFlutter(JSON.stringify(data));
    },
    //获取网络信息
    getNetworkInfo: function () {
        const data = {method: "getNetworkInfo"};
        this.postMessageToFlutter(JSON.stringify(data));
    },


    //////////////////////////////////////////////////////
    //flutter回调
    //////////////////////////////////////////////////////
    //蓝牙设备列表回调，返回Beacon List
    ///{
    //   "uuid": "",
    //   "major": 1,
    //   "minor": 1,
    //   "rssi": 1,
    //   "broadCastInterval": 1,
    //   "testPower": 1,
    //   "transPower": 1,
    //   "address": "",
    //   "battery": 1
    // }
    onBluetoothDevices: function (callback) {
        onBluetoothDeviceCallback = callback;
    },
    //连接蓝牙设备回调
    //disconnected, connecting, connected, disconnecting
    //device_not_found 找不到设备
    //connect_fail 连接失败
    //connect_success 连接成功
    //init_write_success 初始化写入成功
    //init_read_success 初始化读取成功
    //un_connect_device 未连接设备
    //device_info_null 更新设备信息为空
    //transform_to_beacon_error 转换为Beacon失败
    //uuid_form_error uuid格式错误
    //major_range_error major格式错误
    //minor_range_error minor格式错误
    //broadCastInterval_range_error 广播间隔格式错误
    //testPower_range_error 测试功率格式错误
    //transPower_range_error 传输功率格式错误
    //send_pw 向设备发送密码
    //send_uuid 向设备发送uuid
    //send_info 向设备发送详细信息
    //verify_pw_success 校验密码成功
    //update_uuid_success 更新uuid成功
    //update_info_success 更新详细信息成功
    onConnectStatus: function (callback) {
        onConnectStatusCallback = callback;
    },
    //扫码回调，返回String
    onQRCode: function (callback) {
        onQRCodeCallback = callback;
    },
    //socket消息回调
    onSocketMsg: function (callback) {
        onSocketMsgCallback = callback;
    },
    //socket状态回调
    //host_port_null 参数错误
    //connect_fail 连接失败
    //connect_success 连接成功
    //connect_error 连接错误
    //socket_un_initialized 未初始化
    //send_msg_error 发送消息失败
    onSocketStatus: function (callback) {
        onSocketStatusCallback = callback;
    },
    //网络信息回调
    //{
    //   "type": "wifi",
    //   "wifiName": "kcloudM1_7E8924682143",
    //   "wifiBSSID": "5e:cf:7f:da:d3:56",
    //   "wifiIP": "192.168.4.2"
    // }
    onNetworkInfo: function (callback) {
        onNetworkInfoCallback = callback;
    },


    //////////////////////////////////////////////////////
    //总出入口
    //////////////////////////////////////////////////////

    //总入口，接收处理来自Flutter发送的消息，message为json str
    onFlutterMessage: function (message) {
        const json = JSON.parse(message);
        const method = json['method'];
        const data = json['data'];
        switch (method) {
            case 'onBluetoothDevices':
                if (onBluetoothDeviceCallback != null) {
                    onBluetoothDeviceCallback(data);
                }
                break;
            case 'onQRCode':
                if (onQRCodeCallback != null) {
                    onQRCodeCallback(data);
                }
                break;
            case 'onConnectStatus':
                if (onConnectStatusCallback != null) {
                    onConnectStatusCallback(data);
                }
                break;
            case 'onSocketMsg':
                if (onSocketMsgCallback != null) {
                    onSocketMsgCallback(data);
                }
                break;
            case 'onSocketStatus':
                if (onSocketStatusCallback != null) {
                    onSocketStatusCallback(data);
                }
                break;
            case 'onNetworkInfo':
                if (onNetworkInfoCallback != null) {
                    onNetworkInfoCallback(data);
                }
                break;
        }
    },

    //总出口，发送消息到Flutter，message为json str
    postMessageToFlutter(message) {
        // eslint-disable-next-line no-undef
        if (typeof Flutter == 'undefined') {
            return
        }
        Flutter.postMessage(message);
    }

};
export {sdk};

