// 使用示例
// this.$bus.emit('add',1)
// this.$bus.on('add',()=>{})
// this.$bus.off('add')s

const install = function(Vue) {
  const Bus = new Vue({
    methods: {
      emit(event, ...args) {
        this.$emit(event, ...args)
      },
      on(event, callback) {
        this.$on(event, callback)
      },
      off(event, callback) {
        this.$off(event, callback)
      }
    }
  })
  Vue.prototype.$bus = Bus
}
export default install
