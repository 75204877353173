 <!--
 	使用方式请参考 @/views/shareAndDispatch/workManager/record/index .vue
 	各项未设有间距，如需间距，请自行使用样式设置
  -->
<template>
	<div class="filterPopupItem">
		<p class="label">{{ label }}</p>
		<div lang="filterPopupItem-container"><slot></slot></div>
	</div>
</template>

<script>
export default {
	name: 'filterPopupItem',
	components: {},
	props: {
		label: {
			type: [String, Number],
			default: ''
		}
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.filterPopupItem {
	padding: 10px 24px;
	> .label {
		font-size: 14px;
		color: #333333;
		line-height: 18px;
		margin-bottom: 12px;
	}
	.filterPopupItem-container {
		width: 100%;
	}
}
</style>
