import Vue from 'vue'

let wx = require('jweixin-wechat/jweixin-1.4.0')

import { sha1 } from './sha1'

export function createSignature(Vue) {

  Vue.prototype.uploadImageToWeChatAndGet = function(localId, corpInfo) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type == 'app') {
      return new Promise(resolve => {
        if (typeof localStorage[localId] == 'undefined' || localStorage[localId] == null || localStorage[localId] == '') {
          console.log('???')
          resolve({ result: false })
          return
        }
        let imageStr = localStorage[localId]
        try {
          let json = JSON.parse(imageStr);
          resolve({
            image:json,
            result:true
          })
          delete localStorage[localId]
          return
        } catch (e) {
          resolve({ result: false })
          return
        }
      })
    }

    let getNewUrl = (url, info) => {
      let keys = Object.keys(info)
      let newUrl = url
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        if (i == 0) {
          newUrl = newUrl + '?'
        }
        newUrl = newUrl + key + '=' + info[key] + (i != keys.length - 1 ? '&' : '')
      }
      return newUrl
    }

    let weChatToken = (corpInfo) => {
      return new Promise(resolve => {
        let url = getNewUrl('https://qyapi.weixin.qq.com/cgi-bin/gettoken', corpInfo)
        this.$api.weChatLogin.getUrl({
          url: url
        }).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            resolve({ result: false })
            return
          }
          let dataStr = res['data']
          try {
            let data = JSON.parse(dataStr)
            if (this.$valueIsExist(data, ['access_token']) == false) {
              resolve({ result: false })
              return
            }
            resolve({ result: true, token: data['access_token'] })
          } catch (e) {
            resolve({ result: false })
            return
          }
        })

      })
    }

    let updateToServer = (serverId) => {
      return new Promise(resolve => {
        weChatToken(corpInfo).then(res => {
          if (res.result == false) {
            resolve(res)
            return
          }

          let url = `https://qyapi.weixin.qq.com/cgi-bin/media/get?access_token=${res.token}&media_id=${serverId}`
          this.$api.deviceManager.updateDeviceImage({
            url: url
          }).then(res => {
            if (this.$valueIsExist(res, 'data') == false) {
              resolve({
                result: false
              })
              return
            }

            resolve({
              result: true,
              image: res.data
            })
          })
        })
      })
    }

    let getImage = (serverId) => {

      return new Promise(resolve => {
        weChatToken(corpInfo).then(res => {
          if (res.result == false) {
            resolve(res)
            return
          }
          updateToServer(serverId).then(imageres => {
            resolve(imageres)
          })

        })
      })
    }

    let upload = (localId) => {
      console.log('upload localId = ' + localId)
      return new Promise(resolve => {
        console.log('Promise')
        wx.uploadImage({
          localId: localId + '', // 需要上传的图片的本地ID，由chooseImage接口获得
          isShowProgressTips: 0, // 默认为1，显示进度提示
          success: function(res) {

            let serverId = res.serverId // 返回图片的服务器端ID
            resolve({ result: true, serverId: serverId })

          },
          fail: function(e) {
            console.log('upload fail ')
            console.log(e)

            resolve({ result: false })
          }
        })
      })
    }

    return new Promise(resultRes => {
      upload(localId).then(res => {
        if (res.result == false) {
          resultRes(res)
          return
        }
        getImage(res.serverId).then(res1 => {
          resultRes(res1)
        })
      })
    })
  }

  Vue.prototype.addLocalStorageEventByKey = function(key, callBack) {
    let setUpConfig = (vue) => {
      if (vue.$valueIsExist(vue, '_data') == false) {
        return {
          result: false
        }
      }
      let localStorageKey = {}
      if (vue.$valueIsExist(vue, '_data') == false) {
        return {
          result: false
        }
      } else {
        if (vue.$valueIsExist(vue['_data'], 'localStorageKey') == false) {
          vue['_data']['localStorageKey'] = localStorageKey
        } else {
          localStorageKey = vue['_data']['localStorageKey']
        }

      }

      if (vue.$valueIsExist(localStorage, key) == false) {
        localStorageKey[key] = null
      } else {
        localStorageKey[key] = localStorage[key]
      }
      return {
        result: true
      }
    }

    let startSend = () => {
      let localStorageKey = this['_data']['localStorageKey']
      let value = localStorage[key]
      let sValue = localStorageKey[key]
      if (value == sValue) {
        return
      }
      localStorageKey[key] = value
      callBack()
    }

    let res = setUpConfig(this)
    if (res.result == false) {
      return
    }
    this['_data']['startSend'] = startSend
    window.addEventListener('setItemEvent', startSend)
    window.addEventListener('storage', startSend)
  }

  Vue.prototype.addLocalStorageEvent = function(callBack) {
    window.addEventListener('setItemEvent', callBack)
    window.addEventListener('storage', callBack)
  }

  Vue.prototype.removeLocalStorageEvent = function(callBack) {
    let getUpConfig = (vue) => {
      if (vue.$valueIsExist(vue, '_data') == false) {
        return {
          result: false
        }
      }
      if (vue.$valueIsExist(vue, '_data') == false) {
        return {
          result: false
        }
      }

      return {
        result: vue.$valueIsExist(vue['_data'], 'startSend')
      }
    }

    let res = getUpConfig(this)
    if (res.result == false) {
      window.removeEventListener('storage', callBack)
      return
    }
    this['_data']['localStorageKey'] = {}
    let startSend = this['_data']['startSend']

    window.removeEventListener('setItemEvent', startSend)
    window.removeEventListener('storage', startSend)

  }

  Vue.prototype.saveImage = function(imageId) {
    if (imageId == '') {
      localStorage.removeItem('imageId')
    } else {
      localStorage.setItem('imageId', imageId)
    }
  }

  Vue.prototype.getImage = function() {
    return new Promise(resolve => {
      let getLocalImgData = (localId) => {
        return new Promise(resolve1 => {
          let localId = localId
          wx.getLocalImgData({
            localId: localId, // 图片的localID
            success: function(res) {
              resolve1({ result: true, image: res.localData, localID: localId }) // localData是图片的base64数据，可以用img标签显示
            }
          })
        })
      }

      let type = 'web'
      if (typeof localStorage['weChatType'] != 'undefined') {
        type = localStorage['weChatType']
      }
      if (type != 'weChat') {
        resolve({
          result: false
        })
        return
      }
      if (this.$valueIsExist(localStorage, 'imageId') == false) {
        resolve({
          result: false
        })
        return
      }
      let imageId = localStorage['imageId']

      let u = navigator.userAgent
      let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
      if (isAndroid) {
        console.log(imageId)
        resolve({ result: true, image: imageId, localID: imageId })
        return
      }
      let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
      if (isiOS) {
        getLocalImgData(imageId).then(result => {
          resolve(result)
        })
        return
      }
      resolve({ result: false })

    })

  }

  Vue.prototype.replaceToLogin = function(path) {
    wx.miniProgram.reLaunch({
      url: '/pages/index/index'
    })
  }

  Vue.prototype.reLaunch = function(path, query) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type != 'weChat') {
      window.location.href = path
      return
    }
    let url = `/pages/index/index?url=`

    wx.miniProgram.reLaunch({
      // 使用url对小程序传参
      url: url,
      success: function() {

      },
      fail: function() {

      },
      complete: function() {

      }
    })
  }

  Vue.prototype.navigateBack = function() {
    wx.miniProgram.navigateBack({
      delta: 1  // 返回上一级页面。
    })
  }

  Vue.prototype.redirectTo = function(path, query) {
    let queryStr = ''
    let weChat = false
    for (const key in query) {
      queryStr = queryStr + `${queryStr == '' ? '&' : '&'}${key}=${query[key]}`
      if (key == 'weChat') {
        weChat = query[key]
      }
    }

    let webUrl = ''
    if (location.href.indexOf('?code') >= 0) {
      webUrl = `${location.href.split('?code')[0]}#/${path + queryStr}`
    } else if (location.href.indexOf('#') >= 0) {
      webUrl = `${location.href.split('#')[0]}#/${path + queryStr}`
    }

    let url = `/pages/index/index?url=${webUrl}`

    if (weChat) {
      url = `/${path}?url=${webUrl}`
      console.log(url)
    }

    wx.miniProgram.redirectTo({
      // 使用url对小程序传参
      url: url,
      success: function() {

      },
      fail: function() {

      },
      complete: function() {

      }
    })
  }

  Vue.prototype.getWebUrl = function() {
    if (location.href.indexOf('?code') >= 0) {
      return `${location.href.split('?code')[0]}#/`
    } else if (location.href.indexOf('#') >= 0) {
      return `${location.href.split('#')[0]}#/`
    }
    return ''
  }
  Vue.prototype.postMessage = function(config) {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }

    if (type != 'weChat') {
      return
    }
    wx.miniProgram.postMessage(config)
  }

  Vue.prototype.navigateTo = function(path, query) {
    let queryStr = ''
    let weChat = false
    for (const key in query) {
      queryStr = queryStr + `${queryStr == '' ? '&' : '&'}${key}=${query[key]}`
      if (key == 'weChat') {
        weChat = query[key]
      }
    }

    // let webUrl = ''
    // if (location.href.indexOf('?code') >= 0) {
    //   webUrl = `${location.href.split('?code')[0]}#/${path + queryStr}`
    // } else if (location.href.indexOf('#') >= 0) {
    //   webUrl = `${location.href.split('#')[0]}#/${path + queryStr}`
    // }

    let webUrl = this.getWebUrl() + `${path + queryStr}`

    let url = `/pages/index/index?url=${webUrl}`

    if (weChat) {
      url = `/${path}?url=${webUrl}`
      console.log(url)
    }

    if (path == 'mainEnter') {
      wx.miniProgram.redirectTo({ url: url })
      return
    }
    wx.miniProgram.navigateTo({
      // 使用url对小程序传参
      url: url,
      success: function() {

      },
      fail: function() {

      },
      complete: function() {

      }
    })
  }

  /*
   * app上传图片后调用该函数返回结果
   */
  let chooseImageResolve = null

  window.appSendchooseImageResult = (res) => {
    if (chooseImageResolve == null) {
      return
    }
    res['localID'] = res['id']
    res['result'] = true
    localStorage.setItem(res['id'], JSON.stringify(res))
    chooseImageResolve(res)
    chooseImageResolve = null
  }

  Vue.prototype.chooseImage = function() {

    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type == 'app') {
      return new Promise(resolve => {
        if (typeof takePhoto == 'undefined') {
          resolve({ result: false })
          return
        }
        chooseImageResolve = resolve
        let data = {}
        let access_token = ''
        let userInfostr = localStorage.getItem('userTokenInfo')
        try {
          let userInfo = JSON.parse(userInfostr)
          if (typeof userInfo['access_token'] != 'undefined') {
            access_token = userInfo['access_token']
          }
        } catch (e) {

        }
        data['access_token'] = access_token
        data['refresh_token'] = ''
        takePhoto.postMessage(JSON.stringify(data))
      })
    }

    return new Promise(resolve => {
      let getLocalImgData = (localIds) => {
        return new Promise(resolve1 => {
          if (localIds.length == 0) {
            resolve1({ result: false })
            return
          }
          wx.getLocalImgData({
            localId: localIds[0], // 图片的localID
            success: function(res) {
              resolve1({ result: true, image: res.localData, localID: localIds[0] }) // localData是图片的base64数据，可以用img标签显示
            }
          })
        })

      }

      wx.chooseImage({
        count: 1, // 默认9
        sizeType: ['compressed'], // 可以指定是原图还是压缩图，默认二者都有
        sourceType: ['album', 'camera'], // 可以指定来源是相册还是相机，默认二者都有
        defaultCameraMode: 'batch', //表示进入拍照界面的默认模式，目前有normal与batch两种选择，normal表示普通单拍模式，batch表示连拍模式，不传该参数则为normal模式。从3.0.26版本开始支持front和batch_front两种值，其中front表示默认为前置摄像头单拍模式，batch_front表示默认为前置摄像头连拍模式。（注：用户进入拍照界面仍然可自由切换两种模式）
        isSaveToAlbum: 1, //整型值，0表示拍照时不保存到系统相册，1表示自动保存，默认值是1
        success: function(res) {
          let localIds = res.localIds // 返回选定照片的本地ID列表，
          // andriod中localId可以作为img标签的src属性显示图片；
          // iOS应当使用 getLocalImgData 获取图片base64数据，从而用于img标签的显示（在img标签内使用 wx.chooseImage 的 localid 显示可能会不成功）
          console.log(localIds)

          let u = navigator.userAgent
          let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
          if (isAndroid) {
            if (localIds.length == 0) {
              resolve({ result: false })
            } else {
              resolve({ result: true, image: localIds[0], localID: localIds[0] })
            }
            return
          }
          let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
          if (isiOS) {
            getLocalImgData(localIds).then(result => {
              resolve(result)
            })
            return
          }
          resolve({ result: false })
        }
      })
    })
  }

  /*
  * app扫描二维码/条形码 后调用该函数返回结果
  */
  let scanResolve = null

  window.appSendQRCodeResult = (res) => {
    if (scanResolve == null) {
      return
    }
    scanResolve({ result: true, res: res, type: 'qrCode' })
    scanResolve = null
  }

  Vue.prototype.scanQRCode = function() {
    let type = 'web'
    if (typeof localStorage['weChatType'] != 'undefined') {
      type = localStorage['weChatType']
    }
    if (type == 'app') {
      return new Promise(resolve => {
        if (typeof scanQR == 'undefined') {
          resolve({ result: false })
          return
        }
        scanResolve = resolve
        scanQR.postMessage('')
      })
    }
    // CODE_128,
    return new Promise(resolve => {
      wx.scanQRCode({
        desc: 'scanQRCode desc',
        needResult: 1, // 默认为0，扫描结果由企业微信处理，1则直接返回扫描结果，
        scanType: ['qrCode', 'barCode'], // 可以指定扫二维码还是条形码（一维码），默认二者都有
        success: function(res) {
          // 回调
          console.log(res)
          if (typeof res == 'undefined' || res == null || typeof res['resultStr'] == 'undefined' || res['resultStr'] == null) {
            resolve({ result: false })
            return
          }
          let resultStr = res['resultStr']
          if (resultStr.indexOf('CODE_128,') >= 0) {
            let list = resultStr.split('CODE_128,')
            let res = list[list.length - 1]
            resolve({ result: true, res: res, type: 'barCode' })
          } else {
            resolve({ result: true, res: resultStr, type: 'qrCode' })
          }
        },
        error: function(res) {
          resolve({ result: false })
        }
      })
    })

  }

  Vue.prototype.WXEnv = function(callBack) {
    let res = {
      result: false
    }
    var ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      //判断是否是微信环境
      //微信环境
      wx.miniProgram.getEnv(function(res) {
        if (res.miniprogram) {
          // 小程序环境下逻辑
          res.result = true
        }
        callBack(res)
      })
    } else {
      //非微信环境逻辑
      callBack(res)
    }
  }

  Vue.prototype.getSignature = function(config) {
    let updateSignature = (data) => {
      return new Promise(resolve => {
        let userInfo = this.$store.getters.userInfo
        let key = `${userInfo['tenantId']}-signature`
        this.$api.loginApi.setTenantSelfConfig({ key: key, value: JSON.stringify(data) }).then(res => {
        })
      })
    }

    let setUpSignature = (data) => {

      return new Promise(resolve => {
        let noncestr = 'yuyuko'
        let url = location.href.split('#')[0]
        let timestamp = new Date().getTime()
        let str = `jsapi_ticket=${data.ticket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${url}`
        let sha1Str = sha1(str)
        let setting = { timestamp: timestamp, nonceStr: noncestr, signature: sha1Str, ticket: data.ticket }
        updateSignature(setting)
        resolve(setting)
      })
    }

    return new Promise(resolve => {
      let userInfo = this.$store.getters.userInfo
      if (this.$valueIsExist(userInfo, 'tenantId') == false || userInfo['tenantId'] == '') {
        console.log('no tenantId')
        resolve({ result: false, goHead: false })
        return
      }
      let key = `${userInfo['tenantId']}-signature`
      this.$api.loginApi.getTenantSelfConfig({ key: key }).then(res => {
        // 获取tiket 和 token
        try {
          let data = JSON.parse(res.data)
          resolve({ result: true, data: data })
        } catch (e) {
          resolve({ result: false, goHead: true })
        }
      }).catch(e => {
        resolve({ result: false, goHead: false })
        return
      })
    })
  }

  // 设置微信的config
  Vue.prototype.wxConfig = function(config) {
    if (this.$valueIsExist(config, 'corpid') == false) {
      return
    }

    let getNewUrl = (url, info) => {
      let keys = Object.keys(info)
      let newUrl = url
      for (let i = 0; i < keys.length; i++) {
        let key = keys[i]
        if (i == 0) {
          newUrl = newUrl + '?'
        }
        newUrl = newUrl + key + '=' + info[key] + (i != keys.length - 1 ? '&' : '')
      }
      return newUrl
    }

    let updateSignature = (data) => {
      return new Promise(resolve => {
        let userInfo = this.$store.getters.userInfo
        let key = `${userInfo['tenantId']}-signature`
        this.$api.loginApi.setTenantSelfConfig({ key: key, value: JSON.stringify(data) }).then(res => {
        })
      })
    }

    let setUpSignature = (data) => {

      return new Promise(resolve => {
        let noncestr = 'yuyuko'
        let url = location.href.split('#')[0]
        let timestamp = new Date().getTime()
        let str = `jsapi_ticket=${data.ticket}&noncestr=${noncestr}&timestamp=${timestamp}&url=${url}`
        let sha1Str = sha1(str)
        let setting = { timestamp: timestamp, nonceStr: noncestr, signature: sha1Str, ticket: data.ticket }
        updateSignature(setting)
        resolve(setting)
      })
    }

    let jsapiTicket = (dataInfo) => {
      return new Promise(resolve => {
        this.$api.weChatLogin.getUrl({
          url: `https://qyapi.weixin.qq.com/cgi-bin/get_jsapi_ticket?access_token=${dataInfo.token}`
        }).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            resolve({ result: false })
            return
          }
          let dataStr = res['data']
          try {
            let data = JSON.parse(dataStr)
            if (this.$valueIsExist(data, ['ticket']) == false) {
              resolve({ result: false })
              return
            }
            resolve({ result: true, ticket: data['ticket'] })
          } catch (e) {
            resolve({ result: false })
            return
          }
        })

      })
    }

    let weChatToken = (corpInfo) => {
      return new Promise(resolve => {
        let url = getNewUrl('https://qyapi.weixin.qq.com/cgi-bin/gettoken', corpInfo)
        console.log(url)
        this.$api.weChatLogin.getUrl({
          url: url
        }).then(res => {
          if (this.$valueIsExist(res, ['data']) == false) {
            resolve({ result: false })
            return
          }
          let dataStr = res['data']
          try {
            let data = JSON.parse(dataStr)
            if (this.$valueIsExist(data, ['access_token']) == false) {
              resolve({ result: false })
              return
            }
            resolve({ result: true, token: data['access_token'] })
          } catch (e) {
            resolve({ result: false })
            return
          }
        })

      })

    }

    let signatureFaild = (config) => {
      weChatToken(config).then(res => {
        if (res.result == false) {
          console.log('no token')
          return
        }
        console.log('get token')
        console.log(res)
        jsapiTicket(res).then(result => {
          if (result.result == false) {
            console.log('no ticket')
            return
          }
          console.log('get ticket')
          console.log(result)
          setUpSignature(result).then(setting => {
            setUpConfig(setting, config)
          })
        })
      })
    }

    let setUpConfig = (data, config) => {
      return new Promise(resolve => {
        let signature = data.signature
        let nonceStr = data.nonceStr
        let timestamp = parseInt(data.timestamp)
        wx.config({
          beta: true,
          appId: config.corpid, // 必填，公众号的唯一标识
          timestamp: timestamp, // 必填，生成签名的时间戳
          nonceStr: nonceStr, // 必填，生成签名的随机串
          signature: signature,// 必填，签名
          jsApiList: ['scanQRCode', 'chooseImage', 'uploadImage', 'downloadImage'] // 必填，需要使用的JS接口列表
        })
        wx.ready((res) => {
          resolve({ result: true })
        })

        wx.error((res) => {
          resolve({ result: false })
        })
      })
    }

    this.getSignature().then(res => {
      if (res.result == false && res.goHead == false) {
        return
      }
      if (res.result == false && res.goHead) {
        signatureFaild(config)
        return
      }
      setUpConfig(res.data, config).then(result => {
        if (result.result) {
          return
        }
        signatureFaild(config)
      })
    })
  }

}
