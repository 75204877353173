/** 日期一天时间毫秒缩写
 * 8.64e7
 */

	import billingTypeOptions from '@/baseConfig/billingTypeOptions/index.js'

export function validate(rules, dataInfo) {
	return new Promise((resolve, reject) => {
		for (let i = 0; i < rules.length; i++) {
			let rule = rules[i]
			let ruleKey = rule['key']
			if (typeof dataInfo[ruleKey] == 'undefined') {
				continue
			}
			if (dataInfo[ruleKey] == null) {
				reject({
					msg: rule['msg']
				})
				return
			}
			let matchStr = dataInfo[ruleKey]
			let matchTransMatrix = new RegExp(rule['match'], 'gmi')
			let rep = matchStr.match(matchTransMatrix)
			if (rep == null) {
				reject({
					msg: rule['msg']
				})
				return
			}
		}
		resolve({
			result: true
		})
	})
}


export function getTimeMsg(time, fixed) {
	if (time <= 0) {
		return ''
	} else if (time < 60) {
		return time + '秒'
	} else if (time >= 60 && time < 60 * 60) {
		return (time / 60).toFixed(fixed) + '分钟'
	} else if (time >= 60 * 60 && time < 60 * 60 * 24) {
		return (time / (60 * 60)).toFixed(fixed) + '小时'
	} else if (time >= 60 * 60 * 24 && time < 60 * 60 * 24 * 30) {
		return (time / (60 * 60 * 24)).toFixed(fixed) + '天'
	} else if (time >= 60 * 60 * 24 * 30 && time < 60 * 60 * 24 * 30 * 12) {
		return (time / (60 * 60 * 24 * 30)).toFixed(fixed) + '月'
	} else if (time >= 60 * 60 * 24 * 30 * 12 && time < 60 * 60 * 24 * 30 * 12 * 100) {
		return (time / (60 * 60 * 24 * 30 * 12)).toFixed(fixed) + '年'
	} else {
		return '太久拉'
	}
}

/**
 * 格式化时间范围（精确到秒）
 * @param {number} 时间戳，单位是毫秒
 * @return {String} 时间结果
 */
export function formatTimeDurationToSecond(timestamp) {
	if (!timestamp) {
		return ''
	}
	const duration = Math.round((Date.now() - timestamp) / 1000)
	const mistiming = Math.abs(duration)
	var arrr = ['年', '个月', '天', '小时', '分钟']
	var arrn = [31536000, 2592000, 86400, 3600, 60]
	for (var i = 0; i < arrn.length; i++) {
		var inm = Math.floor(mistiming / arrn[i])
		if (inm > 0) {
			return inm + arrr[i] + (duration > 0 ? '前' : '后')
		}
	}
	return '刚刚'
}
export function formatDurationDDMMHH(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长
	 * @param {number} 秒级时长
	 * @return {String} 1天1小时1分
	 */
	if (!value) {
		return defaultText
	}
	value = Number(value)
	const day = Math.floor(value / 86400)
	const h = Math.floor((value % 86400) / 3600)
	const m = Math.floor(((value % 86400) % 3600) / 60)

	return `${day > 0 ? day + '天' : ''}${h > 0 ? h + '小时' : ''}${m > 0 ? m + '分' : ''}`
}
export function formatDayDate(dateTime) {
	if (typeof dateTime == 'undefined' || dateTime == null) {
		return ''
	}
	const date = new Date(dateTime)
	const YY = date.getFullYear() + '-'
	const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
	return YY + MM + DD
}

/**
 * 格式化时间
 * @param {Number} 毫秒级时间戳
 * @return {String} 时:分
 */
export function formatTimeHHMM(value) {
	if (!value) {
		return '-'
	}
	const date = new Date(value)
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	return h + ':' + m
}

/**
 * 格式化日期时间
 * @param {Number} 毫秒级时间戳
 * @return {String} 年-月-日 时:分:秒
 */
export function formatDateTime(value) {
	if (!value) {
		return '-'
	}

	const date = new Date(Number(value))
	const Y = date.getFullYear()


	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}

export function formatDate(dateTime) {
	if (typeof dateTime == 'undefined' || dateTime == null || dateTime == 0 || typeof dateTime == 'string') {
		return '-'
	}

	const date = new Date(dateTime)
	const YY = date.getFullYear() + '-'
	const MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-'
	const DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate())
	const hh = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	const mm = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes())
	const ss = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	return YY + MM + DD + ' ' + hh + mm
}

/** 日期一天时间毫秒缩写
 * 8.64e7
 */

/**
 * 获取日期时间
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  日期时间（yyyy-MM-dd hh:mm:ss）
 */

export function getDateTime(value = new Date().getTime()) {
	var date = new Date(value)
	var yy = date.getFullYear() // 获取当前年份
	var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 // 获取当前月份
	var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 获取当前日
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 获取小时
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 获取分钟
	var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 获取秒
	var dateTime = yy + '-' + MM + '-' + dd + ' ' + hh + ':' + mm + ':' + ss
	return dateTime
}

/**
 * 获取日期
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  日期（yyyy-MM-dd）
 */

export function getDate(value = new Date().getTime()) {
	if (!value) {
		return '-'
	}
	var date = new Date(value)
	var yy = date.getFullYear() // 获取当前年份
	var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 // 获取当前月份
	var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 获取当前日
	var dateTime = yy + '-' + MM + '-' + dd
	return dateTime
}

/**
 * 获取年月
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  年月（yyyy-MM）
 */

export function getYearMonth(value = new Date().getTime()) {
	var date = new Date(value)
	var yy = date.getFullYear() // 获取当前年份
	var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 // 获取当前月份
	var dateTime = yy + '-' + MM
	return dateTime
}

/**
 * 获取时间
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  时间（hh:mm:ss）
 */

export function getTime(value = new Date().getTime()) {
	var date = new Date(value)
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 获取小时
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 获取分钟
	var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 获取秒
	var dateTime = hh + ':' + mm + ':' + ss
	return dateTime
}

/**
 * 获取日期字符串
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  日期字符串（yyyyMMdd）
 */

export function getDateString(value = new Date().getTime()) {
	var date = new Date(value)
	var yy = date.getFullYear().toString() // 获取当前年份
	var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 // 获取当前月份
	var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 获取当前日
	var _date = yy + MM + dd
	return _date
}

/**
 * 获取日期时间字符串
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  日期时间字符串（yyyyMMddhhmmss）
 */

export function getDateTimeString(value = new Date().getTime()) {
	var date = new Date(value)
	var yy = date.getFullYear().toString() // 获取当前年份
	var MM = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1 // 获取当前月份
	var dd = date.getDate() < 10 ? '0' + date.getDate() : date.getDate() // 获取当前日
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours() // 获取小时
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 获取分钟
	var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 获取秒
	var dateTime = yy + MM + dd + hh + mm + ss
	return dateTime
}

/**
 * 获取时间字符串
 * @param {any}  时间格式(可以new Date解析)
 * @return {String}  时间字符串（hhmmss）
 */

export function getTimeString(value = new Date().getTime()) {
	var date = new Date(value)
	var hh = date.getHours() < 10 ? '0' + date.getHours() : date.getHours().toString() // 获取小时
	var mm = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes() // 获取分钟
	var ss = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds() // 获取秒
	var time = hh + mm + ss
	return time
}
export function formatTime(value, defaultText = '-') {
	/**
	 * 格式化时间
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 时:分:秒
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return h + ':' + m + ':' + s
}
/**
 * 判断是否存在值
 * 可判断 字符串，undefined，null，NaN，对象，数组
 * @param {String} 值
 * @return {Boolean} 判断结果
 */
export function isValue(value) {
	if ((Object.prototype.toString.call(value) === '[object String]' && !value) || value === undefined || value ===
		null ||
		Object.is(value, NaN)) {
		return false
	} else if (Object.prototype.toString.call(value) === '[object Array]' && value.length === 0) {
		return false
	} else if (Object.prototype.toString.call(value) === '[object Object]' && Object.keys(value).length === 0) {
		return false
	} else {
		return true
	}
}
export function formatMonthDay(value) {
	/**
	 * 格式化日期
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 月-日
	 */
	if (!value) {
		return '-'
	}
	const date = new Date(value)
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	return M + '月' + D + '日'
}
export function formatDateDay(value) {
	/**
	 * 格式化日期
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 年-月-日
	 */
	if (!value) {
		return '-'
	}
	const date = new Date(value)
	const Y = date.getFullYear()
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	return Y + '年' + M + '月' + D + '日'
}
export function formatBillingType(status) {
	const res=billingTypeOptions.filter(item=>{
		return item.value==status
	})
	if(res.length) return res[0].unit
	return '-'
}

export function formatBillingType1(status) {
	const res=billingTypeOptions.filter(item=>{
		return item.value==status
	})
	if(res.length) return res[0]
	return {}
}

const install = function(Vue) {
	Vue.prototype.validate = validate
	Vue.prototype.getTimeMsg = getTimeMsg
	Vue.prototype.formatTimeDurationToSecond = formatTimeDurationToSecond
	Vue.prototype.formatDayDate = formatDayDate
	Vue.prototype.formatTimeHHMM = formatTimeHHMM
	Vue.prototype.$formatTime = formatTime
	Vue.prototype.formatDateTime = formatDateTime
	Vue.prototype.formatDate = formatDate
	Vue.prototype.$isValue = isValue
	Vue.prototype.formatBillingType = formatBillingType
	Vue.prototype.$formatBillingType1 = formatBillingType1
	Vue.prototype.$formatMonthDay = formatMonthDay
	Vue.prototype.$formatDateDay = formatDateDay
	Vue.prototype.$formatDurationDDMMHH = formatDurationDDMMHH
	Vue.prototype.$getDateTime = getDateTime
	Vue.prototype.$getDate = getDate
}
export default install
