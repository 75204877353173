const req = require.context('./svg', true, /\.svg$/)

// 引入所有svg
const requireAll = () => req.keys().map(req)

// 获取svg名称列表
export function svgNameList() {
  const svgNameArr = []
  const fileList = req.keys()
  fileList.forEach(item => {
    const name1 = item.split('/')
    const name2 = name1[name1.length - 1].split('.svg')
    svgNameArr.push(name2[0])
  })
  return svgNameArr
}

svgNameList();

export default requireAll
