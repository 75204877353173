<!--
 	使用方式请参考 @/views/shareAndDispatch/workManager/record/index .vue
 	各项未设有间距，如需间距，请自行使用样式设置
  -->

<template>
	<van-popup
		v-model="visible"
		round
		:closeable="closeable"
		:close-on-click-overlay="closeOnClickOverlay"
		position="bottom"
		class="filterPopup"
		get-container="body"
		:style="{ height }"
		@close="onClose"
	>
		<div class="filterPopup-container" :class="filterPopupClass">
			<div class="filterPopup-header">
				<p class="title">{{ title }}</p>
			</div>
			<div class="filterPopup-content"><slot></slot></div>
			<div class="filterPopup-footer">
				<button type="button" class="reset-btn" @click="onReset">{{ resetText }}</button>
				<button type="button" class="confirm-btn" @click="onConfirm">{{ confirmText }}</button>
			</div>
		</div>
	</van-popup>
</template>

<script>
export default {
	name: 'filterPopup',
	components: {},
	model: {
		prop: 'value',
		event: 'changeValue'
	},
	props: {
		value: {
			type: Boolean,
			default: false
		},
		height: {
			type: String,
			default: '50%'
		},
		title: {
			type: String,
			default: '筛选'
		},
		closeable: {
			type: Boolean,
			default: true
		},
		closeOnClickOverlay: {
			type: Boolean,
			default: true
		},
		resetText: {
			type: String,
			default: '重置'
		},
		confirmText: {
			type: String,
			default: '确定'
		}
	},
	data() {
		return {
			visible: false,

			fields: []
		};
	},
	watch: {
		value: {
			handler() {
				this.visible = this.value;
			},
			immediate: true
		}
	},
	computed: {
		filterPopupClass() {
			return {
				'filterPopup-have-title': !!this.title
			};
		}
	},
	created() {
		// this.$on('filterPopupItem.addField', this.addField)
	},
	mounted() {},
	beforeDestroy() {
		// this.$off('filterPopupItem.addField', this.addField)
	},
	methods: {
		show() {
			this.visible = true;

			this.fields.forEach(item => {
				if (item.init) {
					item.init();
				}
			});
		},
		hide() {
			this.visible = false;
		},

		addField(field) {
			if (field) {
				this.fields.push(field);
			}
		},

		onClose() {
			this.$emit('close');
		},
		onReset() {
			this.$emit('onReset');
		},
		onConfirm() {
			this.$emit('onConfirm');
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.filterPopup-container {
	width: 100%;
	height: 100%;
	.filterPopup-header {
		text-align: center;
		padding-top: 16px;
		padding-bottom: 10px;
		line-height: 22px;
		height: 48px;
		.title {
			font-size: 18px;
			color: #333;
		}
	}

	.filterPopup-content {
		height: calc(100% - 48px - 52px - 8px);
		margin-bottom: 8px;
		overflow: auto;
	}

	.filterPopup-footer {
		height: 52px;
		display: flex;
		justify-content: space-around;
		padding: 0 12px;
		padding-bottom: 20px;
		button {
			line-height: 32px;
			width: 40%;
			text-align: center;
			border-radius: 32px;
			color: #3a80f6;
			border: 1px solid #3a80f6;
			cursor: pointer;
			font-size: 14px;
		}
		.reset-btn {
			background: #fff;
      height: 40px;
		}
		.confirm-btn {
			background: #3a80f6;
			color: #fff;
      height: 40px;
    }
	}
}
</style>
