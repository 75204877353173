<template>
  <div class="FilterPanelItem">
    <div class="FilterPanelItemCheck">
      <div :style="{ '--margin': margin + 'px' }" v-for="(panelItem, panelIndex) in showLastList" :key="panelIndex"
           class="open_filter_panel_row"
      >

        <div class="open_filter_panel_row_content" @click="close(panelItem)">
          {{ panelItem.name }}
        </div>

      </div>


      <div v-if="!lastItem.open" class="filter_panel_row filter_panel_row_prev" :style="{'--width': lastListWidth }">
        <div v-for="(subitem, index) in showLastItemNotOpenList" :key="subitem.id" class="node_item">
          <el-checkbox v-model="subitem.checked" @change="checkboxChange($event, subitem)"></el-checkbox>
          <span :style="{'color':titleColor }" class="node_item_label" @click="nodeItemMousemoveEvent(0, subitem, index)"
          >{{ subitem[labelKey] }}</span>
          <i class="el-icon-caret-right node_item_icon" :style="{'color':titleColor }"
             v-if="subitem[childKey] && subitem[childKey].length>0"
          />
        </div>
      </div>
      <div v-else class="filter_panel_row filter_panel_row_prev" :style="{'--width': lastListWidth }">
        <div v-for="(item, index) in childrenList" :key="item.id" class="node_item">
          <el-checkbox v-model="item.checked" @change="checkboxChange($event, item)"></el-checkbox>
          <span :style="{'color':titleColor }" class="node_item_label" @click="nodeItemMousemoveEvent(0, item, index)"
          >{{ item[labelKey] }}</span>
          <i class="el-icon-caret-right node_item_icon" :style="{'color':titleColor }"
             v-if="item[childKey] && item[childKey].length>0"
          />
        </div>
      </div>

    </div>


    <div class="FilterPanelItemFooter">
      <el-autocomplete
          @select="handleSelect($event, true)"
          :debounce="600"
          placement="top"
          :value-key="'name'"
          prefix-icon="el-icon-search" size="mini"
          v-model="filterableValue"
          :fetch-suggestions="querySearchAsync"
          placeholder="搜索"
      ></el-autocomplete>
      <el-button class="cancel" size="mini" @click="cancel">取消</el-button>
      <el-button class="sure" size="mini" @click="submit">确定</el-button>
    </div>
  </div>

</template>

<script>

import filterManager from '@/components/filterPanel/filterItemAction'

export default {
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  mixins: [filterManager],
  props: {
    titleColor: {
      default: 'black'
    },
    typeKey: {
      type: String,
      default: () => {
        return ''
      }
    },
    value: {
      type: [String, Number, Array, Object],
      default: function() {
        return []
      }
    },
    // 选项列表
    option: {
      type: [Array, String],
      default: () => {
        return []
      }
    },
    // 选项值对应参数
    valueKey: {
      type: String,
      default: 'id'
    },
    // 显示标签对应参数
    labelKey: {
      type: String,
      default: 'name'
    },
    childKey: {
      type: String,
      default: 'child'
    },
    searchKey: {
      type: String,
      default: 'name'
    }
  },
  data() {
    return {
      filterableValue: '',
      panelOptions: [],
      searchPanelOptions: [],
      nodeItemId: ''
    }
  },
  watch: {
    option: {
      handler() {
        if (this.panelOptions.length > 0) {
          return
        }
        this.panelOptions = this.option
        if (this.panelOptions.length == 0) {
          this.pathInfo.paths = []
          return
        }
        let item = JSON.parse(JSON.stringify(this.panelOptions[0]))
        item['checked'] = true
        item['open'] = false
        this.pathInfo.paths = [item]
      },
      immediate: true,
      deep: true
    },
    value: {
      handler() {

      },
      immediate: true,
      deep: true
    }
  },
  created() {
  },
  methods: {
    changeEvent(e) {
      this.$emit('changeValue', e)
      this.$emit('change', e)
    },
    querySearchAsync: function(queryString, cb) {
      if (queryString == '') {
        cb([])
        return
      }

      let findItem = (dataList, list, queryString) => {
        if (dataList.length == 0) {
          return list
        }

        let children = []

        for (let i = 0; i < dataList.length; i++) {
          let data = dataList[i]
          if (this.$valueIsExist(data, this.childKey)) {
            children = children.concat(data[this.childKey])
          }
          let name = data[this.labelKey]
          if (name.indexOf(queryString) < 0) {
            continue
          }
          list.push(data)
        }
        return findItem(children, list, queryString)
      }
      try {
        let list = findItem(JSON.parse(JSON.stringify(this.panelOptions)), [], queryString)
        cb(list)
      } catch (e) {
        cb([])
      }
    }
  }
}
</script>
<style lang="scss" scoped>

@import "@styles/variables.scss";

.FilterPanelItem {
  position: absolute;
  left: 0;
  width: 100%;
  background: rgba(43, 67, 115, 0.8);
  z-index: 2;

  .FilterPanelItemCheck {
    height: 400px;

    .open_filter_panel_row {
      width: 50px;
      height: calc(100% - 10px);
      float: left;
      background-color: $border_color1;
      margin: 0px 0px 0px var(--margin);


      .open_filter_panel_row_content {
        padding: 0px 2px 0px 2px;
        text-align: center;
        position: relative;
        top: 50%;
        transform: translate(0px, -50%);
        word-wrap: break-word;
        white-space: pre-wrap;
        overflow: hidden;
        table-layout: fixed;
      }

    }


    .filter_panel_row {
      width: var(--width);
      float: left;
      flex: 1;
      height: 100%;
      text-align: center;
      overflow-y: scroll;
      overflow-x: hidden;
      //transition: all 0.2s linear;

      .node_item {
        //height: 40px;
        //line-height: 40px;
        //padding-left: 8px;

        .node_item_label {
          padding-left: 4px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
        }

        .node_item_icon {
          line-height: 40px;
          transition: all 0.2s linear;
        }

        &.node_item_Active {
          background: #f5f7fa;

          .node_item_icon {
            color: $color_primary;
            transform: rotate(90deg);
          }
        }

        &.checkActive {
          color: $color_primary;
        }
      }

      &.filter_panel_row_prev {
        //flex: none;
        //width: 24%;
        //text-align: left;
        //
        //.node_item {
        //  display: flex;
        //
        //  .node_item_label {
        //    flex: 1;
        //  }
        //}
      }
    }
  }

  .FilterPanelItemFooter {
    text-align: right;
    padding: 10px;
    border-top: 1px solid #d8d8d8;

    .sure {
      margin-left: 5px;
      background-color: $color_primary;
      color: white;
    }

    .cancel {
      margin-left: 5px;
    }


    ::v-deep .el-input {
      width: 150px;
    }

    ::v-deep .el-input__inner {
      padding-left: 26px;
      padding-right: 12px;
    }
  }
}
</style>