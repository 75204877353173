import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const originalPush = Router.prototype.push
//修改原型对象中的push方法
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

let router = new Router({
  routes: [{
    path: '/',
    redirect: '/login',
    meta: {
      title: '登录'
    }
  },
    {
      path: '/login',
      name: 'login',
      component: resolve => require(['../views/login/login'], resolve),
      meta: {
        title: ''
      }
    },
    {
      path: '/chartTest',
      name: 'chartTest',
      component: resolve => require(['../views/testPage/chartTest'], resolve),
      meta: {
        title: 'chartTest'
      }
    },
    {
      path: '/deviceManager',
      name: 'deviceManager',
      component: resolve => require(['../views/deviceManager/deviceManager'], resolve),
      meta: {
        title: '资产管理'
      }
    },
    {
      path: '/deviceList',
      name: 'deviceList',
      component: resolve => require(['../views/deviceManager/deviceList/deviceList'], resolve),
      meta: {
        title: '资产分类列表'
      }
    },
    {
      path: '/deviceDetailManager',
      name: 'deviceDetailManager',
      component: resolve => require([
        '../views/deviceManager/deviceDetailManager/deviceDetailManager'
      ], resolve),
      meta: {
        title: '资产详细'
      }
    },
    {
      path: '/devicePandian',
      name: 'devicePandian',
      component: resolve => require([
        '../views/deviceManager/devicePandian/devicePandian'
      ], resolve),
      meta: {
        title: '资产盘点'
      },
      children: [{
        path: 'pandianList',
        name: 'pandianList',
        component: () => import('@views/deviceManager/devicePandian/pandianList'),
        meta: {
          title: '资产盘点'
        }
      },]
    },
    {
      path: '/devicePandianList',
      name: 'devicePandianList',
      component: resolve => require([
        '../views/deviceManager/devicePandianList/devicePandianList'
      ], resolve),
      meta: {
        title: '资产盘点列表'
      },
      children: [{
        path: 'devceiPandianDetailList',
        name: 'devceiPandianDetailList',
        component: () => import('@views/deviceManager/devicePandianList/devceiPandianDetailList'),
        meta: {
          title: '资产盘点列表'
        }
      },]
    },
    {
      path: '/deviceManagerSearch',
      name: 'deviceManagerSearch',
      component: resolve => require([
        '../views/deviceManager/deviceManagerSearch/deviceManagerSearch'
      ], resolve),
      meta: {
        title: '搜索'
      }
    },
    {
      path: '/emergencySupplies',
      name: 'emergencySupplies',
      component: resolve => require(['../views/emergencySupplies/emergencySupplies'], resolve),
      meta: {
        title: '应急物资'
      }
    },
    {
      path: '/bedPersonList',
      name: 'bedPersonList',
      component: resolve => require(['../views/bedPersonList/bedPersonList'], resolve),
      meta: {
        title: '体征监测'
      }
    },
    {
      path: '/editorIP',
      name: 'editorIP',
      component: resolve => require(['../views/editorIP/editorIP'], resolve),
      meta: {
        title: '编辑服务器'
      }
    },
    {
      path: '/breatheManager',
      name: 'breatheManager',
      component: resolve => require(['../views/breatheManager/breatheManager'], resolve),
      meta: {
        title: '设备应急调配'
      }
    },
    {
      path: '/userRole',
      name: 'userRole',
      component: resolve => require(['../views/userRole/index'], resolve),
      meta: {
        title: '账号管理'
      }
    },
    {
      path: '/postBeacon',
      name: 'postBeacon',
      component: resolve => require(['../views/postBeacon/postBeacon'], resolve),
      meta: {
        title: '批量录入信标(svg)'
      }
    },
    {
      path: '/mainEnter',
      name: 'mainEnter',
      component: resolve => require(['../views/mainEnter/mainEnter'], resolve),
      meta: {
        title: '首页'
      }
    },
    {
      path: '/blueToothList',
      name: 'blueToothList',
      component: resolve => require(['../views/blueTooth/blueToochList/blueToothList'], resolve),
      meta: {
        title: '蓝牙列表'
      }
    },
    {
      path: '/blueToothDetail',
      name: 'blueToothDetail',
      component: resolve => require(['../views/blueTooth/blueToothDetail/blueToothDetail'], resolve),
      meta: {
        title: '蓝牙详细'
      }
    },
    {
      path: '/findBlueTooth',
      name: 'findBlueTooth',
      component: resolve => require(['../views/blueTooth/findBlueTooth/index'], resolve),
      meta: {
        title: '资产定位'
      }
    },
    {
      path: '/HelloWorld',
      name: 'HelloWorld',
      component: resolve => require(['../views/testPage/HelloWorld'], resolve),
      meta: {
        title: ''
      }
    },
    {
      path: '/inPut',
      name: 'inPut',
      component: resolve => require(['../components/inPut/inPut'], resolve),
      meta: {
        title: ''
      }
    },
    {
      path: '/badBelt',
      name: 'badBelt',
      component: resolve => require(['../views/bedBelt/badBelt'], resolve),
      meta: {
        title: '床带管理'
      }
    },
    {
      path: '/checkAsset',
      name: 'checkAsset',
      component: resolve => require(['../views/checkAsset/checkAsset'], resolve),
      meta: {
        title: '历史盘点'
      }
    },
    {
      path: '/checkAssetDetail',
      name: 'checkAssetDetail',
      component: resolve => require(['../views/checkAsset/checkAssetDetail/checkAssetDetail'],
        resolve),
      meta: {
        title: '资产详细'
      }
    },
    {
      path: '/assetSimple',
      name: 'assetSimple',
      component: resolve => require(['../views/assetSimple/assetSimple'], resolve),
      meta: {
        title: '资产盘点'
      }
    },
    {
      path: '/assetSimpleTable',
      name: 'assetSimpleTable',
      component: resolve => require(['../views/assetSimple/assetSimpleTable/assetSimpleTable'],
        resolve)
    },
    {
      path: '/findDevice',
      name: 'findDevice',
      component: resolve => require(['../views/findDevice/findDevice'], resolve),
      meta: {
        title: '查找设备'
      }
    },
    {
      path: '/searchDevice',
      name: 'searchDevice',
      component: resolve => require(['../views/searchDevice/searchDevice'], resolve),
      meta: {
        title: '查找设备'
      }
    },
    {
      path: '/mine',
      name: 'mine',
      component: resolve => require(['../views/mine/mine'], resolve),
      meta: {
        title: '我的'
      }
    },
    {
      path: '/alertMessage',
      name: 'alertMessage',
      component: resolve => require(['../views/alertMessge/alertMessage'], resolve),
      meta: {
        title: '设备警报'
      }
    },

    {
      path: '/workManager',
      redirect: '/workManager/home',
      name: 'workManager',
      component: () => import('@views/shareAndDispatch/workManager'),
      meta: {
        title: '业务科室管理'
      },
      children: [{
        path: 'home',
        name: 'workManager-home',
        component: () => import('@views/shareAndDispatch/workManager/home'),
        meta: {
          title: '首页'
        }
      },
        {
          path: 'mine',
          name: 'workManager-home',
          component: () => import('@views/shareAndDispatch/workManager/mine'),
          meta: {
            title: '我的'
          }
        },
        {
          path: 'order',
          name: 'workManager-order',
          component: () => import('@views/shareAndDispatch/workManager/order'),
          meta: {
            title: '订单'
          },
          children: [{
            path: 'shareSetting',
            name: 'workManager-order-shareSetting',
            component: () => import(
              '@views/shareAndDispatch/workManager/order/shareSetting'),
            meta: {
              title: '共享设置'
            }
          },
            {
              path: 'approveWait',
              name: 'workManager-order-approveWait',
              component: () => import(
                '@views/shareAndDispatch/workManager/order/approveWait'),
              meta: {
                title: '待审批'
              }
            },
            {
              path: 'shareOverview',
              name: 'workManager-order-shareOverview',
              component: () => import(
                '@views/shareAndDispatch/workManager/order/shareOverview'),
              meta: {
                title: '共享概览'
              }
            },
            {
              path: 'shareEarnings',
              name: 'workManager-order-shareEarnings',
              component: () => import(
                '@views/shareAndDispatch/workManager/order/shareEarnings'),
              meta: {
                title: '共享收益'
              }
            }
          ]
        },
        {
          path: 'record',
          name: 'workManager-record',
          component: () => import('@views/shareAndDispatch/workManager/record'),
          meta: {
            title: '记录'
          },
          children: [{
            path: 'approveRecord',
            name: 'workManager-record-approveRecord',
            component: () => import(
              '@views/shareAndDispatch/workManager/record/approveRecord'),
            meta: {
              title: '审批记录'
            }
          },
            {
              path: 'borrowRecord',
              name: 'workManager-record-borrowRecord',
              component: () => import(
                '@views/shareAndDispatch/workManager/record/borrowRecord'),
              meta: {
                title: '借用记录'
              }
            },
            {
              path: 'breakdownRecord',
              name: 'workManager-record-breakdownRecord',
              component: () => import(
                '@views/shareAndDispatch/workManager/record/breakdownRecord'),
              meta: {
                title: '报障记录'
              }
            },
            {
              path: 'incomeRecord',
              name: 'workManager-record-incomeRecord',
              component: () => import(
                '@views/shareAndDispatch/workManager/record/incomeRecord'),
              meta: {
                title: '收益记录'
              }
            },
            {
              path: 'shareRecord',
              name: 'workManager-record-shareRecord',
              component: () => import(
                '@views/shareAndDispatch/workManager/record/shareRecord'),
              meta: {
                title: '共享记录'
              }
            }
          ]
        }
      ]
    },

    {
      path: '/deviceBorrow',
      name: 'deviceBorrow',
      component: resolve => require(['../views/shareAndDispatch/deviceBorrow/borrowView.vue'],
        resolve),
      redirect: '/deviceBorrow/deviceMall',
      children: [
        {
          name: 'deviceBorrow_deviceRepair_mine',
          path: 'deviceRepairMine',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceRepairMine'
          ], resolve),
          meta: {
            title: '资产维修'
          },
        },
        {
          name: 'deviceBorrow_borrowTabsPage',
          path: 'borrowTabsPage',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/borrowTabsPage'
          ], resolve),
          meta: {
            title: ''
          },
          children: [{
            name: 'deviceBorrow_allDeviceList',
            path: 'allDeviceList',
            component: resolve => require([
              '../views/shareAndDispatch/deviceBorrow/allDevice/list.vue'
            ], resolve),
            meta: {
              title: '全部'
            }
          },
            {
              name: 'deviceBorrow_mindReservation',
              path: 'mindReservation',
              component: resolve => require([
                '../views/shareAndDispatch/deviceBorrow/mindReservation/list.vue'
              ], resolve),
              meta: {
                title: '我的预约'
              }
            },
            {
              name: 'deviceBorrow_giveBackList',
              path: 'giveBackList',
              component: resolve => require([
                '../views/shareAndDispatch/deviceBorrow/giveBack/list.vue'
              ], resolve),
              meta: {
                title: '归还'
              }
            },
            {
              name: 'deviceBorrow_disinfectList',
              path: 'disinfectList',
              component: resolve => require([
                '../views/shareAndDispatch/deviceBorrow/disinfect/list.vue'
              ], resolve),
              meta: {
                title: '保养消毒'
              }
            },
            {
              name: 'deviceBorrow_deviceScanningList',
              path: 'deviceScanningList',
              component: resolve => require([
                '../views/shareAndDispatch/deviceBorrow/deviceScanning/list.vue'
              ], resolve),
              meta: {
                title: '扫码借用'
              }
            },
            {
              name: 'deviceBorrow_deviceFailureList',
              path: 'deviceFailureList',
              component: resolve => require([
                '../views/shareAndDispatch/deviceBorrow/deviceFailure/list.vue'
              ], resolve),
              meta: {
                title: '报障'
              }
            }
          ]
        },
        {
          name: 'deviceBorrow_homeBorrow',
          path: '/deviceBorrow/homeBorrow',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/homeBorrow/homeBorrow.vue'
          ], resolve),
          meta: {
            title: '设备借用'
          }
        },
        {
          name: 'deviceBorrow_Remind',
          path: '/deviceBorrow/deviceBorrowRemind',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceBorrowRemind/deviceBorrowRemind'
          ], resolve),
          meta: {
            title: '设备归还提醒'
          }
        },
        {
          name: 'deviceBorrow_Extend',
          path: '/deviceBorrow/deviceBorrowExtend',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceBorrowExtend/deviceBorrowExtend'
          ], resolve),
          meta: {
            title: '设备延长归还'
          }
        },
        {
          name: 'deviceBorrow_deviceMall',
          path: '/deviceBorrow/deviceMall',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceMall/list.vue'
          ], resolve),
          meta: {
            title: '预约'
          }
        },
        {
          name: 'deviceBorrow_mallDetails',
          path: '/deviceBorrow/mallDetails',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceMall/mallDetails.vue'
          ], resolve),
          meta: {
            title: '商品详情'
          }
        },
        {
          name: 'deviceBorrow_giveBackDetails',
          path: '/deviceBorrow/giveBackDetails',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/giveBack/details.vue'
          ], resolve),
          meta: {
            title: '消毒归还'
          }
        },
        {
          name: 'deviceBorrow_costDetails',
          path: '/deviceBorrow/costDetails',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/giveBack/costDetails.vue'
          ], resolve),
          meta: {
            title: '费用明细'
          }
        },
        {
          name: 'deviceBorrow_deviceRepair',
          path: '/deviceBorrow/deviceRepair',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceFailure/deviceRepair.vue'
          ], resolve),
          meta: {
            title: '报修'
          }
        },
        {
          name: 'deviceBorrow_only_deviceFailureList',
          path: '/deviceBorrow/deviceFailureList',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceFailure/list.vue'
          ], resolve),
          meta: {
            title: '维修列表'
          }
        },
        {
          name: 'deviceBorrow_disinfectDetails',
          path: '/deviceBorrow/disinfectDetails',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/disinfect/details.vue'
          ], resolve),
          meta: {
            title: '保养消毒'
          }
        },
        {
          name: 'deviceBorrow_deviceDetails',
          path: '/deviceBorrow/deviceDetails',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/mindReservation/deviceDetails.vue'
          ], resolve),
          meta: {
            title: '设备详情'
          }
        },
        {
          name: 'deviceBorrow_submitScanning',
          path: '/deviceBorrow/submitScanning',
          component: resolve => require([
            '../views/shareAndDispatch/deviceBorrow/deviceScanning/submitScanning.vue'
          ], resolve),
          meta: {
            title: '扫码借用'
          }
        },
        {
          name: 'deviceBorrow_borrowMine',
          path: '/deviceBorrow/borrowMine',
          component: resolve => require(['../views/shareAndDispatch/deviceBorrow/borrowMine'],
            resolve),
          meta: {
            title: '我的'
          }
        },
        {
          path: '/deviceRecord',
          name: 'deviceRecord',
          component: () => import('@views/shareAndDispatch/deviceRecord'),
          meta: {
            title: '记录'
          },
          children: [{
            path: 'reservation',
            name: 'deviceRecord-reservation',
            component: () => import(
              '@views/shareAndDispatch/deviceRecord/reservation'),
            meta: {
              title: '预约记录'
            }
          },
            {
              path: 'borrow',
              name: 'deviceRecord-borrow',
              component: () => import(
                '@views/shareAndDispatch/deviceRecord/borrow'),
              meta: {
                title: '借用记录'
              }
            },
            {
              path: 'breakdown',
              name: 'deviceRecord-breakdown',
              component: () => import(
                '@views/shareAndDispatch/deviceRecord/breakdown'),
              meta: {
                title: '报障记录'
              }
            },
            {
              path: 'cost',
              name: 'deviceRecord-cost',
              component: () => import(
                '@views/shareAndDispatch/deviceRecord/cost'),
              meta: {
                title: '费用记录'
              }
            }
          ]
        }
      ]
    },
    {
      path: '/deviceShareDetail/detailDetail',
      name: 'deviceShareDetail_device_detail',
      component: resolve => require([
        '../views/shareAndDispatch/device/deviceShareDetail/deviceDetail/detailDetail'
      ], resolve),
      meta: {
        title: '设备详情'
      }
    },
    {
      path: '/deviceShareDetail',
      name: 'deviceShareDetail',
      component: resolve => require([
        '../views/shareAndDispatch/device/deviceShareDetail/deviceShareDetail'
      ], resolve),
      meta: {
        title: '设备共享',
        showBottomBar: true
      },
      redirect: '/deviceShareDetail/shareDetail',
      children: [{
        name: 'deviceShareDetail_shareDetail',
        path: '/deviceShareDetail/shareDetail',
        component: resolve => require([
          '../views/shareAndDispatch/device/deviceShareDetail/shareDetail/shareDetail'
        ], resolve),
        meta: {
          title: '共享池',
          showBottomBar: true
        }
      },
        {
          name: 'deviceShareDetail_repairDetail',
          path: '/deviceShareDetail/repairDetail',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceShareDetail/repairDetail/repairDetail'
          ], resolve),
          meta: {
            title: '维修工单',
            showBottomBar: true
          }
        },
        {
          name: 'deviceShareDetail_checkDetail',
          path: '/deviceShareDetail/checkDetail',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceShareDetail/checkDetail/checkDetail'
          ], resolve),
          meta: {
            title: '盘点',
            showBottomBar: true
          }
        },
        {
          name: 'deviceShareDetail_deviceMine',
          path: '/deviceShareDetail/deviceMine',
          component: resolve => require(['../views/shareAndDispatch/deviceMine/deviceMine'],
            resolve),
          meta: {
            title: '我的',
            showBottomBar: true
          }
        }
      ]
    },
    {
      path: '/deviceShareStatus',
      name: 'deviceShareStatus',
      component: resolve => require([
        '../views/shareAndDispatch/device/deviceShareDetail/deviceStatus/deviceShareStatus'
      ], resolve),
      meta: {
        title: '设备状态'
      },
      children: []
    },
    {
      path: '/deviceRepair',
      name: 'deviceRepair',
      redirect: '/deviceRepair',
      component: resolve => require(['../views/shareAndDispatch/device/deviceRepair'], resolve),
      meta: {
        title: '报障维修'
      },
      children: [{
        path: 'reapirList',
        name: 'reapirList',
        component: () => import(
          '@views/shareAndDispatch/device/deviceRepair/reapirList/index.vue'),
        meta: {
          title: ''
        },
        children: [{
          path: 'remindResponse',
          name: 'reapirList-remindResponse',
          component: () => import(
            '@views/shareAndDispatch/device/deviceRepair/reapirList/remindResponse'
            ),
          meta: {
            title: '催单响应'
          }
        },
          {
            path: 'repairedExamine',
            name: 'reapirList-repairedExamine',
            component: () => import(
              '@views/shareAndDispatch/device/deviceRepair/reapirList/repairedExamine'
              ),
            meta: {
              title: '维修审核'
            }
          },
          {
            path: 'repairedHandle',
            name: 'reapirList-repairedHandle',
            component: () => import(
              '@views/shareAndDispatch/device/deviceRepair/reapirList/repairedHandle'
              ),
            meta: {
              title: '维修处理'
            }
          },
          {
            path: 'repairResponse',
            name: 'reapirList-repairResponse',
            component: () => import(
              '@views/shareAndDispatch/device/deviceRepair/reapirList/repairResponse'
              ),
            meta: {
              title: '维修响应'
            }
          },
          {
            path: 'repairEvaluateList',
            name: 'reapirList-repairEvaluateList',
            component: () => import(
              '@views/shareAndDispatch/device/deviceRepair/reapirList/repairEvaluateList'
              ),
            meta: {
              title: '维修考评'
            }
          }
        ]
      },
        {
          name: 'deviceRepair_repairedOrder',
          path: '/deviceRepair/repairedOrder',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/order/repairedOrder'
          ], resolve),
          meta: {
            title: '报修工单'
          }
        },
        {
          name: 'deviceRepair_appointRole',
          path: '/deviceRepair/appointRole',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/role/appointRole'
          ], resolve),
          meta: {
            title: '指派工程师'
          }
        },
        {
          name: 'deviceRepair_addRole',
          path: '/deviceRepair/addRole',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/role/addRole'
          ], resolve),
          meta: {
            title: '添加'
          }
        },
        {
          name: 'deviceRepair_repairInfo',
          path: '/deviceRepair/repairInfo',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/handle/repairInfo'
          ], resolve),
          meta: {
            title: '维修信息'
          }
        },
        {
          name: 'deviceRepair_auditDetails',
          path: '/deviceRepair/auditDetails',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/handle/auditDetails'
          ], resolve),
          meta: {
            title: '审核详情'
          }
        },
        {
          name: 'deviceRepair_handleDetails',
          path: '/deviceRepair/handleDetails',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/handle/handleDetails'
          ], resolve),
          meta: {
            title: '处理详情'
          }
        },
        {
          name: 'deviceRepair_reminderInfo',
          path: '/deviceRepair/reminderInfo',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/handle/reminderInfo'
          ], resolve),
          meta: {
            title: '催单信息'
          }
        },
        {
          name: 'deviceRepair_repair_evaluate',
          path: '/deviceRepair/repairEvaluate',
          component: resolve => require([
            '../views/shareAndDispatch/device/deviceRepair/handle/repairEvaluate'
          ], resolve),
          meta: {
            title: '工单评价'
          }
        }

      ]
    },

    {
      path: '/deviceView1',
      redirect: '/deviceView1/home',
      name: 'deviceView',
      component: () => import('@views/shareAndDispatch/deviceView'),
      meta: {
        title: '设备科管理'
      },
      children: [
        {
          path: 'deviceRepairHomeIndex',
          name: 'deviceView-repairHome-index',
          component: () => import('@views/shareAndDispatch/deviceView/deviceRepairHomeIndex'),
          meta: {
            title: '资产维修'
          },
          redirect: '/deviceView1/deviceRepairHomeIndex',
          children: [
            {
              path: 'deviceRepairHome',
              name: 'deviceView-repairHome',
              component: () => import('@views/shareAndDispatch/deviceView/deviceRepairHome'),
              meta: {
                title: '资产维修'
              }
            },
            {
              path: 'deviceRepairMine',
              name: 'deviceView-repairMine',
              component: () => import('@views/shareAndDispatch/deviceView/deviceRepairMine'),
              meta: {
                title: '我的'
              }
            }
          ]
        },
        {
          path: 'home',
          name: 'deviceView-home',
          component: () => import('@views/shareAndDispatch/deviceView/home'),
          meta: {
            title: '首页'
          }
        },
        {
          path: 'mine',
          name: 'deviceView-home',
          component: () => import('@views/shareAndDispatch/deviceView/mine'),
          meta: {
            title: '我的'
          }
        },
        {
          path: 'deviceWorkerDeal',
          name: 'deviceView-device-worker-deal',
          component: () => import(
            '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerDeal/deviceWorkerDeal'
            ),
          meta: {
            title: '维修处理'
          }
        },
        {
          path: 'deviceWorkerHome',
          name: 'deviceView-worker-home',
          redirect: '/deviceView1/deviceWorkerHome/deviceWorkerAll',
          component: () => import(
            '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerHome'),
          meta: {
            title: '工程师处理'
          },
          children: [{
            path: 'deviceWorker',
            name: 'deviceView-worker',
            component: () => import(
              '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorker'
              ),
            meta: {
              title: '处理中'
            }
          },
            {
              path: 'deviceWorkerAll',
              name: 'deviceView-worker-all',
              component: () => import(
                '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerAll'
                ),
              meta: {
                title: '全部'
              }
            },
            {
              path: 'deviceWorkUndeal',
              name: 'deviceView-worker-undeal',
              component: () => import(
                '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkUndeal'
                ),
              meta: {
                title: '未处理'
              }
            },
            {
              path: 'deviceWorkerFinished',
              name: 'deviceView-worker-finished',
              component: () => import(
                '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkerFinished'
                ),
              meta: {
                title: '已处理'
              }
            }
          ]

        },
        {
          path: 'share',
          name: 'deviceView-share',
          redirect: '/deviceView1/share/shareOverview',
          component: () => import('@views/shareAndDispatch/deviceView/share'),
          meta: {
            title: '共享调度'
          },
          children: [{
            path: 'shareOverview',
            name: 'deviceView-share-shareOverview',
            component: () => import(
              '@views/shareAndDispatch/deviceView/share/shareOverview'),
            meta: {
              title: '共享调度概览'
            }
          },
            {
              path: 'shareDevice',
              name: 'deviceView-share-shareDevice',
              component: () => import(
                '@views/shareAndDispatch/deviceView/share/shareDevice'),
              meta: {
                title: '共享池'
              }
            },
            {
              path: 'shareSetting',
              name: 'deviceView-share-shareSetting',
              component: () => import(
                '@views/shareAndDispatch/deviceView/share/shareSetting'),
              meta: {
                title: '共享设置'
              }
            },
            {
              path: 'shareOrder',
              redirect: '/deviceView1/share/shareOrder/shareOrder1',
              name: 'deviceView-share-shareOrder',
              component: () => import(
                '@views/shareAndDispatch/deviceView/share/shareOrder'),
              meta: {
                title: '共享设备'
              },
              children: [{
                path: 'shareOrder1',
                name: 'deviceView-share-shareOrder-shareOrder1',
                component: () => import(
                  '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder1'
                  ),
                meta: {
                  title: '预约'
                }
              },
                {
                  path: 'shareOrder2',
                  name: 'deviceView-share-shareOrder-shareOrder2',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder2'
                    ),
                  meta: {
                    title: '待审批'
                  }
                },
                {
                  path: 'shareOrder3',
                  name: 'deviceView-share-shareOrder-shareOrder3',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder3'
                    ),
                  meta: {
                    title: '报障'
                  }
                },
                {
                  path: 'shareOrder4',
                  name: 'deviceView-share-shareOrder-shareOrder4',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder4'
                    ),
                  meta: {
                    title: '已归还'
                  }
                },
                {
                  path: 'shareOrder5',
                  name: 'deviceView-share-shareOrder-shareOrder5',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder5'
                    ),
                  meta: {
                    title: '借用中'
                  }
                },
                {
                  path: 'shareOrder6',
                  name: 'deviceView-share-shareOrder-shareOrder6',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder6'
                    ),
                  meta: {
                    title: '锁定'
                  }
                },
                {
                  path: 'shareOrder7',
                  name: 'deviceView-share-shareOrder-shareOrder7',
                  component: () => import(
                    '@views/shareAndDispatch/deviceView/share/shareOrder/page/shareOrder7'
                    ),
                  meta: {
                    title: '已审批'
                  }
                }
              ]
            }
          ]
        },
        {
          path: 'reponseHome',
          name: 'deviceView-reponse-home',
          component: () => import(
            '../views/shareAndDispatch/deviceView/response/responseHome/resonseHome'),
          redirect: '/deviceView1/reponseHome/repairResponseRecord',
          meta: {
            title: '响应记录'
          },
          children: [{
            path: 'dealRecord',
            name: 'deviceViewrepair-deal-record',
            component: () => import(
              '../views/shareAndDispatch/deviceView/response/dealRecord/dealRecord'
              ),
            meta: {
              title: '维修处理记录'
            }
          },
            {
              path: 'checkRecord',
              name: 'deviceViewrepair-check-record',
              component: () => import(
                '../views/shareAndDispatch/deviceView/response/checkRecord/checkRecord'
                ),
              meta: {
                title: '维修审核记录'
              }
            },
            {
              path: 'repairRecord',
              name: 'deviceViewrepair-repair-record',
              component: () => import(
                '../views/shareAndDispatch/deviceView/response/repairRecord/repairRecord'
                ),
              meta: {
                title: '报修记录'
              }
            },
            {
              path: 'repairResponseRecord',
              name: 'deviceViewrepair-response-record',
              component: () => import(
                '../views/shareAndDispatch/deviceView/response/repairResponseRecord/repairResponseRecord'
                ),
              meta: {
                title: '维修响应记录'
              }
            },
            {
              path: 'remindResponseRecord',
              name: 'deviceViewrepair-remind-response-record',
              component: () => import(
                '../views/shareAndDispatch/deviceView/response/remindResponseRecord/remindResponseRecord'
                ),
              meta: {
                title: '催单响应记录'
              }
            }
          ]
        },
        {
          path: 'responseDetail',
          name: 'deviceView-response-detail',
          component: () => import(
            '../views/shareAndDispatch/deviceView/response/responseDetail/responseDetail'
            ),
          meta: {
            title: '记录详情'
          }
        }, {
          path: 'deviceWorkDetail',
          name: 'deviceView-device-worker-detail',
          component: () => import(
            '../views/shareAndDispatch/deviceView/deviceWorker/deviceWorkDetail/deviceWorkDetail'
            ),
          meta: {
            title: '记录详情'
          }
        }
      ]
    },
    {
      path: '/deviceView',
      name: 'deviceView',
      component: resolve => require(['../views/shareAndDispatch/device/deviceView'], resolve),
      meta: {
        title: '设备科管理',
        showBottomBar: true
      },
      redirect: '/deviceView1/home'
    },
    {
      path: '/deviceMQ',
      name: 'deviceMQ',
      component: resolve => require(['../views/deviceMQ/deviceMQ'], resolve),
      meta: {
        title: '设备MQ'
      },
      children: [{
        name: 'deviceMQ/Z2View',
        path: 'Z2View',
        component: resolve => require(['../views/deviceMQ/Z2View/Z2View'], resolve),
        meta: {
          title: '普通资产'
        }
      },
        {
          name: 'deviceMQ/Z3View',
          path: 'Z3View',
          component: resolve => require(['../views/deviceMQ/Z3View/Z3View'], resolve),
          meta: {
            title: 'Z3'
          }
        },
        {
          name: 'deviceMQ/Z4View',
          path: 'Z4View',
          component: resolve => require(['../views/deviceMQ/Z4View/Z4View'], resolve),
          meta: {
            title: 'Z4'
          }
        },
        {
          name: 'deviceMQ/Z5View',
          path: 'Z5View',
          component: resolve => require(['../views/deviceMQ/Z5View/Z5View'], resolve),
          meta: {
            title: 'Z5'
          }
        },
        {
          name: 'deviceMQ/S2View',
          path: 'S2View',
          component: resolve => require(['../views/deviceMQ/S2View/S2View'], resolve),
          meta: {
            title: 'S2'
          }
        },
        {
          name: 'deviceMQ/IncubatorView',
          path: 'IncubatorView',
          component: resolve => require(['../views/deviceMQ/IncubatorView/IncubatorView'],
            resolve),
          meta: {
            title: '暖箱'
          }
        },
        {
          name: 'deviceMQ/BraceletView',
          path: 'BraceletView',
          component: resolve => require(['../views/deviceMQ/BraceletView/BraceletView'],
            resolve),
          meta: {
            title: '手环/胸卡'
          }
        }
      ]
    },
    {
      path: '/device-Station-MQ',
      name: 'device-Station-MQ',
      component: resolve => require(['../views/deviceMQ/device-Station-MQ'], resolve),
      meta: {
        title: '设备MQ'
      },
      children: [{
        name: 'device-Station-MQ/Z2View',
        path: 'Z2View',
        component: resolve => require(['../views/deviceMQ/Z2View/Z2View'], resolve),
        meta: {
          title: 'Z2'
        }
      },
        {
          name: 'device-Station-MQ/Z3View',
          path: 'Z3View',
          component: resolve => require(['../views/deviceMQ/Z3View/Z3View'], resolve),
          meta: {
            title: 'Z3'
          }
        },
        {
          name: 'device-Station-MQ/Z4View',
          path: 'Z4View',
          component: resolve => require(['../views/deviceMQ/Z4View/Z4View'], resolve),
          meta: {
            title: 'Z4'
          }
        },
        {
          name: 'device-Station-MQ/Z5View',
          path: 'Z5View',
          component: resolve => require(['../views/deviceMQ/Z5View/Z5View'], resolve),
          meta: {
            title: 'Z5'
          }
        },
        {
          name: 'device-Station-MQ/S2View',
          path: 'S2View',
          component: resolve => require(['../views/deviceMQ/S2View/S2View'], resolve),
          meta: {
            title: 'S2'
          }
        },
        {
          name: 'device-Station-MQ/IncubatorView',
          path: 'IncubatorView',
          component: resolve => require(['../views/deviceMQ/IncubatorView/IncubatorView'],
            resolve),
          meta: {
            title: '暖箱'
          }
        },
        {
          name: 'device-Station-MQ/BraceletView',
          path: 'BraceletView',
          component: resolve => require(['../views/deviceMQ/BraceletView/BraceletView'],
            resolve),
          meta: {
            title: '手环/胸卡'
          }
        }
      ]
    },
    {
      path: '/mapRenderList',
      name: 'mapRenderList',
      component: resolve => require(['../views/mapRender/mapRenderList'], resolve),
      meta: {
        title: '地图列表'
      }
    },
    {
      path: '/mapRenderView',
      name: 'mapRenderList',
      component: resolve => require(['../views/mapRender/mapRenderView/mapRenderView'], resolve),
      meta: {
        title: '地图渲染'
      }
    },
    {
      path: '/station-MQ',
      name: 'station-MQ',
      component: resolve => require(['../views/deviceMQ/station-MQ'], resolve),
      meta: {
        title: '基站MQ'
      },
      children: [{
        name: 'station-MQ/Z2View',
        path: 'Z2View',
        component: resolve => require(['../views/deviceMQ/Z2View/Z2View'], resolve),
        meta: {
          title: 'Z2'
        }
      },
        {
          name: 'station-MQ/Z3View',
          path: 'Z3View',
          component: resolve => require(['../views/deviceMQ/Z3View/Z3View'], resolve),
          meta: {
            title: 'Z3'
          }
        },
        {
          name: 'station-MQ/Z4View',
          path: 'Z4View',
          component: resolve => require(['../views/deviceMQ/Z4View/Z4View'], resolve),
          meta: {
            title: 'Z4'
          }
        },
        {
          name: 'station-MQ/Z5View',
          path: 'Z5View',
          component: resolve => require(['../views/deviceMQ/Z5View/Z5View'], resolve),
          meta: {
            title: 'Z5'
          }
        },
        {
          name: 'station-MQ/S2View',
          path: 'S2View',
          component: resolve => require(['../views/deviceMQ/S2View/S2View'], resolve),
          meta: {
            title: 'S2'
          }
        },
        {
          name: 'station-MQ/IncubatorView',
          path: 'IncubatorView',
          component: resolve => require(['../views/deviceMQ/IncubatorView/IncubatorView'],
            resolve),
          meta: {
            title: '暖箱'
          }
        },
        {
          name: 'station-MQ/BraceletView',
          path: 'BraceletView',
          component: resolve => require(['../views/deviceMQ/BraceletView/BraceletView'],
            resolve),
          meta: {
            title: '手环/胸卡'
          }
        }
      ]
    },

    {
      path: '/transportManage',
      name: 'transportManage',
      component: resolve => require(['../views/transportManage/index.vue'], resolve),
      redirect: '/transportManage/workOrder',
      meta: {
        title: '手术室运送管理系统'
      },
      children: [{
        path: 'workOrder',
        name: 'transportManage-workOrder',
        component: resolve => require(['../views/transportManage/workOrder'], resolve),
        redirect: '/transportManage/workOrder/sendOrders',
        meta: {
          title: '工单'
        },
        children: [{
          path: 'sendOrders',
          name: 'transportManage-workOrder-sendOrders',
          component: resolve => require([
            '../views/transportManage/workOrder/sendOrders'
          ], resolve),
          meta: {
            title: '派单'
          }
        },
          {
            path: 'taskQuery',
            name: 'transportManage-workOrder-taskQuery',
            component: resolve => require([
              '../views/transportManage/workOrder/taskQuery'
            ], resolve),
            meta: {
              title: '任务查询'
            }
          },
          {
            path: 'workers',
            name: 'transportManage-workOrder-workers',
            component: resolve => require([
              '../views/transportManage/workOrder/workers'
            ], resolve),
            meta: {
              title: '工勤管理'
            }
          }
        ]
      },
        {
          path: 'transport',
          name: 'transportManage-transport',
          redirect: '/transportManage/transport/workOrderList',
          component: resolve => require(['../views/transportManage/transport'], resolve),
          meta: {
            title: '转运'
          },
          children: [
            {
              path: 'workOrderList',
              name: 'transportManage-transport-workOrderList',
              component: resolve => require(['../views/transportManage/transport/workOrderList'], resolve),
              meta: {
                title: '工单列表'
              }
            },
            {
              path: 'workOrderDetail',
              name: 'transportManage-transport-workOrderDetail',
              component: resolve => require(['../views/transportManage/transport/workOrderDetail'], resolve),
              meta: {
                title: '工单详情'
              }
            }
          ]
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  if (to.meta.title) { //判断是否有标题
    document.title = to.meta.title
  }
  next() //执行进入路由，如果不写就不会进入目标页
})

export default router
