<template>
	<div class="KcEmpty">
		<p v-if="type == 'text'" class="KcEmpty-text">暂无数据</p>
		<div v-else class="KcEmpty-img-wrap">
			<img class="KcEmpty-img" :src="require('@assets/images/empty-img.png')" alt="" />
			<span class="KcEmpty-img-text">暂无数据</span>
		</div>
	</div>
</template>

<script>
export default {
	name: 'KcEmpty',
	components: {},
	props: {
		type: {
			type: String,
			default: 'default' // default-默认  text-文字
		}
	},
	data() {
		return {};
	},
	created() {},
	mounted() {},
	methods: {}
};
</script>

<style lang="scss" scoped>
@import '@styles/variables.scss';
.KcEmpty {
	display: flex;
	justify-content: center;
	margin-top: 100px;
	.KcEmpty-img-wrap {
		display: flex;
		align-items: center;
		flex-direction: column;
		.KcEmpty-img {
			width: 160px;
		}
		.KcEmpty-img-text {
			margin-top: 12px;
			font-size: 14px;
			color: #999;
		}
	}
	.KcEmpty-text {
		font-size: 14px;
		color: #999;
	}
}
</style>
