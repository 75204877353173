import Vue from 'vue'
import Vuex from 'vuex'
import da from 'element-ui/src/locale/lang/da'
import {
  setSessionStorage,
  getSessionStorage
} from '@/utils/sessionStorage.js'

Vue.use(Vuex)
const store = new Vuex.Store({
	state: {
		orgInfo: [],
		alertInfo: {},
		userInfo: {},
		userTokenInfo: {},
		prejectConfig: getSessionStorage('prejectConfig') || null
	},
	getters: {
		userInfo: state => {
			let info = {
				allPhoto: '',
				customerId: '',
				customerName: '',
				id: '',
				name: '',
				photo: '',
				roleList: [],
				tenantId: '',
				tenantName: '',
				username: ''
			}
			let userInfostr = localStorage.getItem('userInfo')
			try {
				let userInfo = JSON.parse(userInfostr)
				for (const key in userInfo) {
					let value = userInfo[key]
					if (typeof value == 'undefined' || value == null) {
						value = ''
					}
					info[key] = value
				}
				state.userInfo = info
				return info
			} catch (e) {
				return info
			}
			return info
		},
		userToken: state => {
			if (typeof state.userTokenInfo.access_token == 'undefined' ||
				state.userTokenInfo.access_token == null ||
				state.userTokenInfo.access_token == '') {
				let userInfostr = localStorage.getItem('userTokenInfo')
				try {
					let userInfo = JSON.parse(userInfostr)
					if (typeof userInfo['access_token'] == 'undefined') {
						return ''
					}
					return userInfo['access_token']
				} catch (e) {
					return ''
				}
				return ''
			}
			return state.userTokenInfo.access_token
		},
		alertInfo: state => {
			return state.alertInfo
		},
		orgInfo: state => {
			if (typeof localStorage['orgInfo'] == 'undefined' || localStorage['orgInfo'] == null) {
				return state.orgInfo
			}
			try {
				let str = localStorage['orgInfo'];
				return JSON.parse(str)
			} catch (e) {

			}
			return state.orgInfo
		},
		isWXEnV: state => {
			let envRes = localStorage.getItem('env')
			if (envRes == null || typeof envRes == 'undefined') {
				return {
					result: false
				}
			}
			try {
				let env = JSON.parse(envRes)
				return {
					result: true,
					isWXEnV: env.result
				}
			} catch (e) {
				return {
					result: false
				}
			}
		},
		prejectConfig: state => {
			return state.prejectConfig
		}
	},
	mutations: {
		setOrgInfo(state, orgInfo) {
			let getValue = (data, key, defaultValue) => {
				if (typeof data[key] == 'undefined' || data[key] == null) {
					return defaultValue
				}
				return data[key]
			}

			let updateData = (data) => {
				let info = {
					id: '',
					name: '',
					children: []
				}
				info['id'] = getValue(data, 'id', '')
				info['name'] = getValue(data, 'name', '')
				info['children'] = getValue(data, 'children', [])
				info['pid'] = getValue(data, 'pid', '')
				let keys = Object.keys(data)
				for (let i = 0; i < keys.length; i++) {
					delete data[keys[i]]
				}
				data['id'] = info['id']
				data['name'] = info['name']
				data['children'] = info['children']
				data['pid'] = info['pid']
			}

			let findChildren = (dataList) => {
				if (dataList.length == 0) {
					return
				}
				let children = []
				for (let i = 0; i < dataList.length; i++) {
					let data = dataList[i]
					updateData(data)
					children = children.concat(getValue(data, 'children', []))
				}
				findChildren(children)
			}
			findChildren(orgInfo)
			state.orgInfo = orgInfo
			localStorage.setItem('orgInfo', JSON.stringify(orgInfo))
		},
		setAlertInfo(state, alertInfo) {
			localStorage.setItem('alertInfo', JSON.stringify(alertInfo))
			state.alertInfo = alertInfo
		},
		setEnv(state, envRes) {
			localStorage.setItem('env', JSON.stringify(envRes))
		},
		setUpUserToken(state, dataInfo) {
			localStorage.setItem('userTokenInfo', JSON.stringify(dataInfo))
			state.userTokenInfo = dataInfo
		},
		setUpUserInfo(state, dataInfo) {
			localStorage.setItem('userInfo', JSON.stringify(dataInfo))
			state.userInfo = dataInfo
		},
		setPrejectConfig(state, data) {
			state.prejectConfig = data
			setSessionStorage('prejectConfig', data)
		}
	},
	actions: {}
})

export default store
