import request from '../request/request'

let url = window.ipConfig.VUE_APP_ASSETURL

function getVUE_APP_ASSETURLURL_Url() {
  return window.ipConfig.VUE_APP_ASSETURL
}

export default {
  getDeviceTotalCount(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url()+ '/api/cat/dataCountInfo',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getZ2MsgList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/z2DataInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getZ3MsgList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/z3DataInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getZ4MsgList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/z4DataInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getZ5MsgList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/z5DataInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getS2MsgList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/s2braceletInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  getIncubatorInfoList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/incubatorInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },

  getBraceletInfoList(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/braceletInfoList',
      method: 'post',
      responseType: 'json',
      params
    })
  },
  // 基站统计
  getStationDetail(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/baseStationDetail',
      method: 'post',
      responseType: 'json',
      params
    })
  },

  // 设备统计
  getDeviceDetail(params) {
    return request({
      url: getVUE_APP_ASSETURLURL_Url() + '/api/cat/deviceDetailView',
      method: 'post',
      responseType: 'json',
      params
    })
  },

}
