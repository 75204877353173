export function formatDateTime(value, defaultText = '-') {
	/**
	 * 格式化日期时间
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 年-月-日 时:分:秒
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const Y = date.getFullYear()
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return Y + '-' + M + '-' + D + ' ' + h + ':' + m + ':' + s
}

export function formatDate(value, defaultText = '-') {
	/**
	 * 格式化日期
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 年-月-日
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const Y = date.getFullYear()
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	return Y + '-' + M + '-' + D
}

export function formatDateMMDD(value, defaultText = '-') {
	/**
	 * 格式化日期
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 月-日
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	return M + '-' + D
}

export function formatTime(value, defaultText = '-') {
	/**
	 * 格式化时间
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 时:分:秒
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return h + ':' + m + ':' + s
}

export function formatTimeHHMM(value, defaultText = '-') {
	/**
	 * 格式化时间
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 时:分
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	return h + ':' + m
}

export function formatMonthDay(value, defaultText = '-') {
	/**
	 * 格式化月日
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 月-日
	 */
	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	return M + '-' + D
}

export function formatMonthDayTime(value, defaultText = '-') {
	/**
	 * 格式化月日时间
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 月-日 时:分:秒
	 */

	if (!value) {
		return defaultText
	}
	const date = new Date(value)
	const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1)
	const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate()
	const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours()
	const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()
	const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()
	return M + '-' + D + ' ' + h + ':' + m + ':' + s
}

export function formatTimestampDurationForDayHours(value, defaultText = 0) {
	/**
	 * 格式化时间戳，转化为时长
	 * @param {Number} 毫秒级时间戳
	 * @return {String} 1天1小时1分前
	 */
	if (!value) {
		return defaultText
	}
	const _value = Math.ceil((new Date().getTime() - Number(value)) / 1000)

	const day = Math.floor(_value / 86400)
	const h = Math.floor((_value % 86400) / 3600)
	const m = Math.floor(((_value % 86400) % 3600) / 60)

	return `${day > 0 ? day + '天' : ''}${h > 0 ? h + '小时' : ''}${m > 0 ? m + '分' : ''}前`
}

export function formatMSDuration(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长
	 * @param {Number} 毫秒级时长
	 * @return {String} 1天1小时1分1秒
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const day = Math.floor(value / 86400)
	const h = Math.floor((value % 86400) / 3600)
	const m = Math.floor(((value % 86400) % 3600) / 60)
	const s = ((value % 86400) % 3600) % 60

	return `${day > 0 ? day + '天' : ''}${h > 0 ? h + '小时' : ''}${m > 0 ? m + '分' : ''}${s > 0 ? s + '秒' : ''}`
}

export function formatMSDurationForDate(value, defaultText = 0) {
	/**
	 * 格式化时间，转化时长（天为单位）
	 * @param {number} 毫秒级时长
	 * @return {String} 1天1小时
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const day = Math.floor(value / 86400)
	const h = Math.ceil((value % 86400) / 3600)

	return `${day > 0 ? day + '天' : ''}${h > 0 ? h + '小时' : ''}`
}

export function formatMSDurationForDay(value, defaultText = 0) {
	/**
	 * 格式化时间，转化时长（天为单位）
	 * @param {number} 毫秒级时长
	 * @return {String} 1天
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const day = (value / 86400).toFixed(1)

	return day + '天'
}

export function formatMSDurationForTime(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长（时分秒）
	 * @param {number} 毫秒级时长
	 * @return {String} 1小时1分1秒
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const h = Math.floor(value / 3600)
	const m = Math.floor((value % 3600) / 60)
	const s = (value % 3600) % 60

	return `${h > 0 ? h + '小时' : ''}${m > 0 ? m + '分' : ''}${s > 0 ? s + '秒' : ''}`
}

export function formatMSDurationForHour(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长（小时为单位）
	 * @param {number} 毫秒级时长
	 * @return {String} 1小时
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const h = (value / 3600).toFixed(1)

	return h + '小时'
}

export function formatMSDurationForHourNotText(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长（小时为单位）
	 * @param {number} 毫秒级时长
	 * @return {String} 1小时
	 */

	if (!value) {
		return defaultText
	}
	value = Math.ceil(Number(value) / 1000)
	const h = (value / 3600).toFixed(1)

	return h
}

export function formatDuration(value, defaultText = 0) {
	/**
	 * 格式化时间，转化为时长
	 * @param {number} 秒级时长
	 * @return {String} 1天1小时1分1秒
	 */
	if (!value) {
		return defaultText
	}
	value = Number(value)
	const day = Math.floor(value / 86400)
	const h = Math.floor((value % 86400) / 3600)
	const m = Math.floor(((value % 86400) % 3600) / 60)
	const s = ((value % 86400) % 3600) % 60

	return `${day > 0 ? day + '天' : ''}${h > 0 ? h + '小时' : ''}${m > 0 ? m + '分' : ''}${s > 0 ? s + '秒' : ''}`
}

export function formatEnable(value) {
	/**
	 * 格式化启用/未启用
	 * @param {Number,String} 状态
	 * @return {String} 启用/未启用
	 */
	switch (Number(value)) {
		case 0:
			return '未启用'
		case 1:
			return '启用'
		default:
			return ''
	}
}

export function formatSex(value) {
	/**
	 * 格式性别
	 * @param {Number,String} 状态
	 * @return {String} 男/女
	 */
	switch (Number(value)) {
		case 0:
			return '女'
		case 1:
			return '男'
		default:
			return ''
	}
}

export function formatDeleted(value) {
	/**
	 * 格式化删除状态
	 * @param {Number,String} 状态
	 * @return {String} 删除/未删除
	 */
	switch (Number(value)) {
		case 0:
			return '未删除'
		case 1:
			return '删除'
		default:
			return ''
	}
}

export function formatFilterPanelCascaderMultipleValue(value) {
	/**
	 * 格式级联选择器级联多选值
	 * @param {array} 值
	 * @return {String} 格式化后返回值
	 */
	const _value = JSON.parse(JSON.stringify(value))
	if (_value.length) {
		if (Object.prototype.toString.call(_value[0]) === '[object Array]') {
			const valueArr = []
			_value.forEach(_item => {
				valueArr.push(_item[_item.length - 1])
			})

			return valueArr
		} else {
			return _value
		}
	} else {
		return []
	}
}

export function formatYesOrNo(value) {
	/**
	 * 格式是或否
	 * @param {Number,String}
	 * @return {String} 是/否
	 */
	switch (Number(value)) {
		case 0:
			return '否'
		case 1:
			return '是'
		default:
			return ''
	}
}

export function formatToThousands(num) {
	/**
	 * 格式化千分值
	 * @param {Number}
	 * @return {String} 1,000
	 */
	var result = []
	var counter = 0

	num = (num || 0).toString()
	const decimal = num.split('.')[1] || ''
	num = num.split('.')[0]
	num = num.split('')
	for (var i = num.length - 1; i >= 0; i--) {
		counter++
		result.unshift(num[i])
		if (!(counter % 3) && i != 0) {
			result.unshift(',')
		}
	}
	return result.join('') + (decimal ? '.' + decimal : '')
}


/**
 * 格式化时间范围（精确到秒）
 * @param {number} 时间戳，单位是毫秒
 * @return {String} 时间结果
 */
export function formatTimeDurationToSecond(timestamp) {
	if (!timestamp) {
		return ''
	}
	const duration = Math.round((Date.now() - timestamp) / 1000)
	const mistiming = Math.abs(duration)
	var arrr = ['年', '个月', '天', '小时', '分钟', '秒']
	var arrn = [31536000, 2592000, 86400, 3600, 60, 1]
	for (var i = 0; i < arrn.length; i++) {
		var inm = Math.floor(mistiming / arrn[i])
		if (inm > 0) {
			return inm + arrr[i] + (duration > 0 ? '前' : '后')
		}
	}
	return '刚刚'
}
