import request from '../request/request'

function getVUE_APP_RFIDURL_Url() {
  return window.ipConfig.VUE_APP_RFIDURL
}

export default {
  // 盘点任务列表
  taskList(data) {
    return request({
      url: getVUE_APP_RFIDURL_Url() +'/api/task/taskList',
      method: 'post',
      data
    })
  },
  // 盘点资产列表
  deviceInventoryList(data) {
    return request({
      url: getVUE_APP_RFIDURL_Url() +'/api/task/deviceInventoryList',
      method: 'post',
      data
    })
  },
  // 批量添加盘点记录
  deviceInventoryRecordBatchAdd(data) {
    return request({
      url: getVUE_APP_RFIDURL_Url() +'/api/inventory/deviceInventoryRecordBatchAdd',
      method: 'post',
      data
    })
  },


}