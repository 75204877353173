import request from '../request/request'


function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}


export default {
  getWechatToken(url, data) {
    return request({
      url: url,
      method: 'post',
      data
    })
  },
  setTenantSelfConfig(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/redis/set',
      method: 'post',
      data
    })
  },
  getTenantSelfConfig(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/redis/get',
      method: 'post',
      data
    })
  },
  setTenantConfig(data) {
    return request({
      url: process.env.VUE_APP_BASEURL + '/api/oauth/redis/set',
      method: 'post',
      data
    })
  },
  getTenantConfig(data) {
    return request({
      url: process.env.VUE_APP_BASEURL + '/api/oauth/redis/get',
      method: 'post',
      data
    })
  },
  getSettingConfig(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/redis/get',
      method: 'post',
      data
    })
  },
  // wechat获取key
  getLoginKey(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/token',
      method: 'post',
      data
    })
  },
  // 注册账号
  updateUserByWechat(data) {
    return request({
      headers: { 'Content-Type': 'multipart/form-data' },
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/wechatComRegister',
      method: 'post',
      FormData: true,
      data
    })
  },
  getVrifyCode(params) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/code/defaultKaptcha',
      method: 'get',
      responseType: 'blob',
      params
    })
  },
  // 获取公钥
  getPublicKey(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/get/public/key',
      method: 'post',
      data
    })
  },
  // 登录
  login(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/oauth/token',
      method: 'post',
      data
    })
  }
}
