import request from '@api/request/request'

function getVUE_APP_BASEURL_Url() {
  return window.ipConfig.VUE_APP_BASEURL
}

export default {
  getMapList(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/svg/map/list',
      method: 'post',
      data
    })
  },
  getMapDetail(data) {
    return request({
      url: getVUE_APP_BASEURL_Url() + '/api/svg/map/detail',
      method: 'post',
      data
    })
  }
}
