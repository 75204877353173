// 防抖
export function debounce(fn, delay = 200) {
  let timeout = null
  return function() {
    const that = this
    const args = arguments
    clearTimeout(timeout)

    if (!args.length) {
      timeout = setTimeout(() => {
        fn.apply(that, args)
      }, delay)
    }
  }
}
