import axios from 'axios'
import qs from 'qs'
import { vm } from '@/main.js'
import store from '@/store'

var permitRequest = true // 是否允许请求


// 请求配置
const instance = axios.create({
  withCredentials: false,
  timeout: 30000,
  headers: {
    // "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
    'Content-Type': 'application/x-www-form-urlencoded',
    // 'Access-Control-Allow-Origin':'*',
    // 'Access-Control-Allow-Methods':'GET, POST, OPTIONS',
  }
})

// 开放接口(无需token)
const openApi = ['/api/http/get', '/code/defaultKaptcha', '/oauth/get/public/key', '/oauth/token', '/api/oauth/redis/get', '/open/common/get/wxsign2']
// 判断开放接口
function checkOpenApi(path) {
  const check = openApi.some(e => {
    return path.indexOf(e) > -1
  })
  return check
}

instance.interceptors.request.use(
  config => {

    let access_token = '';
    let userInfostr = localStorage.getItem('userTokenInfo')
    try {
      let userInfo = JSON.parse(userInfostr);
      if (typeof userInfo['access_token'] != 'undefined') {
        access_token = userInfo['access_token']
      }
    } catch (e) {

    }

    if (access_token && !checkOpenApi(config.url)) {
      config.headers.Authorization = 'Bearer ' + access_token
    }

    // 判断post请求不是FormData则进行处理
    if (Object.prototype.toString.call(config.data) !== '[object FormData]' && config.method === 'post') {
      config.data = qs.stringify(config.data)
    }
    return config
  },
  error => {
    console.log(error)
  }
)


// 退出登录
function loginOut() {

  let loginType = () => {
    if (typeof localStorage['loginType'] == 'undefined') {
      return ''
    }
    let loginType = localStorage.getItem('loginType')
    return loginType;
  }

  permitRequest = false
  permitRequest = true
  vm.$clearLocal();
  vm.$store.commit('setUpUserToken', {})
  vm.$store.commit('setOrgInfo', [])
  let query = {};
  if (loginType() == 'weChatQiYe') {
    query['type'] = 'weChat'
  }
  vm.$router.replace({
    path: '/login',
    query: query
  })
  return Promise.reject({ })
}

function debounce(fn, delay = 200, immediate) {
  // immediate  是否立即执行
  let timeout = null
  return function() {
    const that = this
    const args = arguments
    clearTimeout(timeout)
    if (immediate) {
      const callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, delay)
      // 立即执行
      if (callNow) fn.apply(that, args)
    } else {
      // 不会立即执行
      timeout = setTimeout(() => {
        fn.apply(that, args)
      }, delay)
    }
  }
}

var loginOutDebounce = debounce(function() {
  permitRequest = true
  vm.$clearLocal();
  vm.$store.commit('setUpUserToken', {})
  this.$store.commit('setUpOrgInfo', [])
  vm.$router.replace({
    path: '/login',
    query: {}
  })
}, 500, true)

// http response 拦截器
instance.interceptors.response.use(
  response => {
    var code = response.data.code
    if (!code || code === 200) {
      return response.data
    } else if ((code === 401 || code === 450) && !checkOpenApi(response.config.url)) {
      return loginOut()
    } else if (code === 444 || code === 304 || code === 403) {
      return loginOut()
    } else {
      return Promise.reject(response)
    }
  },
  error => {
    if (typeof error['response'] != 'undefined' && typeof error['response']['status'] != 'undefined') {
      let status = error['response']['status'];
      if (status === 401 || code === 450) {
        loginOut();
      }
    }
    return Promise.reject(error)
  }
)

export default instance
