<template>
  <div class="filterPanel" @click.stop="">
    <div v-if="viewStyle=='one'">
      <div class="searchView">
        <slot name="title"></slot>
        <el-input :style="{'--width':$changePxToRem(searchWidth + 'px')}" size="medium" class="search"
                  prefix-icon="el-icon-search" clearable v-model="baseData.query"
        >
        </el-input>
        <div class="other" :style="{color:titleColor}" @click="searchEvent">搜索</div>
        <slot name="otherAction"></slot>
      </div>

      <div class="filterhidden">
        <div v-for="item in layout" class="filterTitle">
          <el-popover
              @hide="advancedKey='';item.visible=false;"
              v-model="item.visible"
              :width="popover"
              placement="bottom"
              trigger="click" >
            <div slot="reference" style="width: auto" class="titleItem"
                 :class="{ 'advanced_search_show': advancedKey == item.key}" @click="showCollapse(item)" >
              <span class="tltle"> {{ item.label }}</span>
              <i :style="{color:titleColor}" class="el-icon-caret-bottom"/>
            </div>

            <div class="filterContent">
              <div class="showAdvancedSearch">
                <FilterPanelItem @cancel="advancedKey='';item.visible=false;"
                                 v-for="(item, index) in layout" :key="index" :childKey="item.childKey"
                                 :title-color="'white'"
                                 :searchKey="item.searchKey"
                                 :labelKey="item.labelKey"
                                 v-model="baseData[item.key]"
                                 @submit="submit($event,item)"
                                 :ref="item.key"
                                 :option="options[item.optionKey]" :type-key="item.key"
                />
              </div>
            </div>
          </el-popover>
        </div>

        <div class="filterTitle" v-if="showDate">
          <div class="titleItem dateContent"
               :class="{'advanced_search_show': advancedKey == 'timeStamp'}">
            <el-date-picker style="margin-right: 10px" :clearable="false"
                            size="mini"
                            @change="searchEvent" v-model="timeStamp" @blur="dataBlur"
                            @focus="dataFocus" type="date" placeholder="选择日期"
            >
            </el-date-picker>
            <i :style="{color:titleColor}" class="el-icon-caret-bottom dateIcon"/>
          </div>
        </div>

      </div>
    </div>

    <div v-if="viewStyle=='two'">
      <div class="searchView">
        <slot name="title"></slot>
        <el-input :style="{'--width':$changePxToRem((searchWidth + 15) + 'px')}" size="medium" class="search"
                  prefix-icon="el-icon-search" clearable v-model="baseData.query"
        >

        </el-input>


        <slot name="otherAction"></slot>

        <div :style="{width:$changePxToRem((searchWidth - 30) + 'px'),height:'100%',  float:'left',display:'flex'}">
          <div v-for="item in layout" class="two_action">
            <div class="filterTitle"
                 style="position: relative;top: 50%;transform: translate(0px, -50%);overflow: hidden;padding: 0px"
            >
              <el-popover
                  @hide="advancedKey='';item.visible=false;"
                  v-model="item.visible"
                  :width="popover"
                  placement="bottom"
                  trigger="click"
              >
                <div slot="reference" style="width: auto" class="titleItem"
                     :class="{ 'advanced_search_show': advancedKey == item.key}" @click="showCollapse(item)"
                >
                  <svg-icon v-if="item.svg" class="icon" width="14px" height="14px" :name="item.svg"></svg-icon>
                  <span class="tltle" :style="{color:titleColor}"> {{ item.label }}</span>
                  <i :style="{color:titleColor}" class="el-icon-caret-bottom"/>
                </div>

                <div class="filterContent">
                  <div class="showAdvancedSearch">
                    <FilterPanelItem @cancel="advancedKey='';item.visible=false;"
                                     v-for="(item, index) in layout" :key="index" :childKey="item.childKey"
                                     :title-color="titleColor"
                                     :searchKey="item.searchKey"
                                     :labelKey="item.labelKey"
                                     v-model="baseData[item.key]"
                                     @submit="submit($event,item)"
                                     :ref="item.key"
                                     :option="options[item.optionKey]" :type-key="item.key"
                    />
                  </div>
                </div>
              </el-popover>
            </div>
          </div>
          <div class="two_action" v-if="showDate">
            <div class="filterTitle"
                 style="position: relative;top: 50%;transform: translate(0px, -50%);overflow: hidden;padding: 0px"
            >
              <div class="titleItem dateContent" style="width: auto"
                   :class="{'advanced_search_show': advancedKey == 'timeStamp'}"
              >
                <svg-icon style="margin-right: 5px;position: relative;top: 50%;transform: translate(0px, -50%);"
                          class="icon" width="10px" height="10px" name="date"
                ></svg-icon>
                <el-date-picker :style="{'--titleColor':titleColor, 'margin-right': '9px'}" :clearable="false"
                                size="mini"
                                @change="searchEvent" v-model="timeStamp" @blur="dataBlur"
                                @focus="dataFocus" type="date" placeholder="选择日期"
                >
                </el-date-picker>
                <i :style="{color:titleColor}" class="el-icon-caret-bottom dateIcon"/>
              </div>
            </div>


          </div>
        </div>



        <div class="seachButtonContent" :style="{'--width':$changePxToRem(searchButtonPosition + 'px')}">
          <div class="seachButton" @click="searchEvent">搜索</div>
        </div>

      </div>

    </div>


  </div>
</template>

<script>

export default {
  name: 'filterPanel',
  model: {
    prop: 'value',
    event: 'changeValue'
  },
  computed: {
    searchButtonPosition() {
      let width = window.screen.width
      return 100
    },
    searchWidth() {
      let width = 10 + 10;
      if (this.showSolt) {
        width = width + 40
      }
      if (this.showTitle) {
        width = width + 50
      }

      if (this.viewStyle == 'two') {
        width = width + 70 * this.layout.length + this.showDate * 80
      } else {
        width = width + 50
      }

      return width
    }
  },
  props: {
    viewStyle: {
      default: 'one'
    },
    titleColor: {
      default: 'black'
    },
    showTitle: {
      default: false
    },
    showSolt: {
      default: false
    },
    // 绑定值对象数组列表
    value: {
      type: [Object, String],
      default: () => {
        return {}
      }
    },
    options: {
      type: [Object, String],
      default: () => {
        return {}
      }
    },
    layout: {
      type: [Array, String],
      default: () => {
        return []
      }
    },
    showDate: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      popover: 350,
      showAdvancedSearch: false,
      advancedKey: '',
      timeStamp: new Date().getTime(),
      baseData: {
        isTenantIdChild: 1,
        isCategoryIdChild: 1,
        query: ''
      }
    }
  },
  created() {
  },
  mounted() {
    this.popover = window.screen.width * 0.95
    window.addEventListener('click', this.clickOther)
  },
  beforeDestroy() {
    window.removeEventListener('click', this.clickOther)
  },
  methods: {
    submit: function(res, item) {
      this.advancedKey = ''
      item.visible = false
      if (res.result == false) {
        return
      }
      this.baseData[res.type] = res.result.id
      this.showAdvancedSearch = false
      this.$emit('searchEvent', this.baseData)
    },
    showCollapse(item) {
      if (this.advancedKey == item.key) {
        this.showAdvancedSearch = !this.showAdvancedSearch
      } else {
        this.advancedKey = item.key
        this.showAdvancedSearch = true
      }
    },
    clickOther() {
      this.showAdvancedSearch = false
    },
    dataBlur() {
      this.advancedKey = ''
    },
    dataFocus() {
      this.advancedKey = 'timeStamp'
      for (let i = 0; i < this.layout.length; i++) {
        let item = this.layout[i]
        item['visible'] = false
      }
    },
    searchEvent() {
      this.showAdvancedSearch = false
      for (let i = 0; i < this.layout.length; i++) {
        let data = this.layout[i]
        if (this.$valueIsExist(this.$refs, data.key) == false) {
          continue
        }
        let res = this.$refs[data.key][0].getResult()
        if (res.result == false) {
          continue
        }
        this.baseData[data.key] = res.id
      }
      if (this.showDate) {
        let timeStr = this.formatDayDate(this.timeStamp)
        this.baseData.startTime = timeStr + ' 00:00:00'
        this.baseData.endTime = timeStr + ' 23:59:59'
      }
      this.$emit('searchEvent', this.baseData)
    }
  }
}
</script>
<style lang="scss" scoped>
@import "@styles/variables.scss";

.filterPanel {
  //background: $background_color2;
  width: 100%;
  z-index: 99;

  .searchView {
    width: 100%;
    height: 52px;

    .search {
      margin-left: 10px;
      width: calc(100% - var(--width));
      position: relative;
      top: 50%;
      transform: translate(0px, -50%);
      float: left;
    }

    .other {
      height: 28px;
      width: 40px;
      text-align: center;
      line-height: 28px;
      float: left;
      position: relative;
      top: 50%;
      right: 10px;
      margin-left: 15px;
      transform: translate(0px, -50%);
    }

    .two_action {
      margin-right: 5px;
      height: 100%;
    }

    .seachButtonContent {
      width: 60px;
      height: 100%;
      position: relative;
      transform: translate(var(--width), -0px);

      .seachButton {
        background-image: linear-gradient(to left, $color_primary, $color_primary, #4ca6f6, #cfe8ff);
        width: 100%;
        height: 32px;
        position: absolute;
        top: 50%;
        transform: translate(-2px, -50%);
        border-radius: 17px;
        line-height: 30px;
        text-align: center;
        color: white;
        clear: both;
      }
    }

  }

  .filterhidden {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 28px;
  }

  .filterContent {
    width: 100%;
    position: relative;
  }

  .filterTitle {
    display: flex;
    padding: 0 10px;
    overflow: scroll;
    padding-bottom: 12px;
    justify-content: space-between;

    .titleItem {
      width: 100px;
      text-align: left;
      flex: none;

      .tltle {
        display: inline-block;
        padding: 0px 8px 0px 3px;
        font-size: 14px;
      }

      &.advanced_search_show {
        i {
          transform: rotate(180deg);
          color: #0279e1;
        }
      }

      i {
        transition: all 0.2s linear;
        font-size: 14px;
      }
    }

    .dateContent {
      width: 90px;
      overflow: hidden;
      display: flex;
      justify-content: space-around;
      position: relative;

      .dateIcon {
        position: absolute;
        right: 0;
        margin-top: 8px;
      }
    }
  }
}

::v-deep .search .el-input__inner {
  border-radius: 20px;
}

::v-deep .dateContent .el-input__inner {
  padding: 0px;
  border: 0;
  background-color: rgba(0, 0, 0, 0);
  color: var(--titleColor);
}

::v-deep .el-date-editor.el-input {
  width: 75px;
}

::v-deep .dateContent .el-input__prefix {
  display: none;
}


</style>

<style>
.el-popover {
  background: rgba(43, 67, 115, 0.8);
  border: none;
  padding: 12px 12px 0px 12px;
  border-radius: 0px;
}

.el-popover[x-placement^=bottom] .popper__arrow {
  border-bottom-color: rgba(43, 67, 115, 0.5);
}

.el-popover[x-placement^=bottom] .popper__arrow::after {
  border-bottom-color: rgba(43, 67, 115, 0.5);
}

</style>

